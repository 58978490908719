export const UPDATE_PAYMENT_DETAILS = 'UPDATE_PAYMENT_DETAILS';
export const UPDATE_PAYMENT_DETAILS_SUCCESS = 'UPDATE_PAYMENT_DETAILS_SUCCESS';
export const UPDATE_PAYMENT_DETAILS_ERROR = 'UPDATE_PAYMENT_DETAILS_ERROR';
export const UPDATE_PAYMENT_DETAILS_INITIAL = 'UPDATE_PAYMENT_DETAILS_INITIAL';

export interface UpdatePaymentRequest {
    userId: string;
    salesChannelId: number;
    cardToken: string;
    street?: string;
    apartment?: string;
    city?: string;
    zipcode?: string;
}

export interface UpdatePaymentError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface UpdatePaymentState {
    data: unknown | null;
    loading: boolean;
    error: UpdatePaymentError | null;
}

export interface SetUpdatePaymentDetailsInitialAction {
    type: typeof UPDATE_PAYMENT_DETAILS_INITIAL;
}

export interface UpdatePaymentDetailsAction {
    type: typeof UPDATE_PAYMENT_DETAILS;
}

export interface UpdatePaymentSuccessAction {
    type: typeof UPDATE_PAYMENT_DETAILS_SUCCESS;
    payload: unknown;
}

export interface UpdatePaymentErrorAction {
    type: typeof UPDATE_PAYMENT_DETAILS_ERROR;
    payload: UpdatePaymentError;
}

export type UpdatePaymentAction =
    | UpdatePaymentDetailsAction
    | UpdatePaymentSuccessAction
    | UpdatePaymentErrorAction
    | SetUpdatePaymentDetailsInitialAction;
