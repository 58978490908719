import {
    REGISTER,
    RegisterAction,
    RegisterError,
    RegisterState,
    REGISTER_ERROR,
    REGISTER_SUCCESS,
    REGISTER_INITIAL,
} from '../_types/register';

const initialState: RegisterState = {
    data: null,
    loading: false,
    error: null,
};

export default (state = initialState, action: RegisterAction): RegisterState => {
    switch (action.type) {
        case REGISTER_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: {} as RegisterError,
            };
        case REGISTER:
            return {
                ...state,
                loading: true,
            };
        case REGISTER_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case REGISTER_INITIAL:
            return initialState;
        default:
            return state;
    }
};
