import {
    GET_PASS_SETTINGS,
    GET_PASS_SETTINGS_ERROR,
    GET_PASS_SETTINGS_SUCCESS,
    PassSettingsAction,
    PassSettingsState,
} from '../_types/passSettings';

const initialState: PassSettingsState = {
    data: null,
    loading: false,
    error: null,
};

export default (state = initialState, action: PassSettingsAction): PassSettingsState => {
    switch (action.type) {
        case GET_PASS_SETTINGS_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case GET_PASS_SETTINGS:
            return {
                ...state,
                loading: true,
            };
        case GET_PASS_SETTINGS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
