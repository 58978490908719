export const CONFIRM_EMAIL = 'CONFIRM_EMAIL';
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_ERROR = 'CONFIRM_EMAIL_ERROR';

export interface ConfirmEmailRequest {
    token: string;
}

export interface ConfirmEmail {
    signupToken: string;
    createdPassword: string;
}

export interface ConfirmEmailError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface ConfirmEmailState {
    data: ConfirmEmail | null;
    loading: boolean;
    error: ConfirmEmailError | null;
}

export interface ConfirmEmailAction {
    type: typeof CONFIRM_EMAIL;
}

export interface ConfirmEmailSuccessAction {
    type: typeof CONFIRM_EMAIL_SUCCESS;
    payload: ConfirmEmail;
}

export interface ConfirmEmailErrorAction {
    type: typeof CONFIRM_EMAIL_ERROR;
    payload: ConfirmEmailError;
}

export type ConfirmEmailOptionsAction = ConfirmEmailAction | ConfirmEmailSuccessAction | ConfirmEmailErrorAction;
