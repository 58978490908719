import {
    EventSingle,
    GET_UPCOMING_EVENTS,
    GET_UPCOMING_EVENTS_ERROR,
    GET_UPCOMING_EVENTS_SUCCESS,
    UpcomingEventsAction,
    UpcomingEventsError,
} from '../_types/event';

export const setUpcomingEventsLoading = (): UpcomingEventsAction => {
    return {
        type: GET_UPCOMING_EVENTS,
    };
};

export const setUpcomingEventsError = (err: UpcomingEventsError): UpcomingEventsAction => {
    return {
        type: GET_UPCOMING_EVENTS_ERROR,
        payload: err,
    };
};

export const setUpcomingEventsSuccess = (data: Array<EventSingle>): UpcomingEventsAction => {
    return {
        type: GET_UPCOMING_EVENTS_SUCCESS,
        payload: data,
    };
};
