import {
    GET_PASS_SUBSCRIPTION,
    GET_PASS_SUBSCRIPTION_ERROR,
    GET_PASS_SUBSCRIPTION_SUCCESS,
    PassSubscriptionAction,
    PassSubscriptionState,
    PassSubscriptionSingle,
    CANCEL_PASS_SUBSCRIPTION,
    CANCEL_PASS_SUBSCRIPTION_ERROR,
    CANCEL_PASS_SUBSCRIPTION_SUCCESS,
    ADD_PASS_SUBSCRIPTION_SUCCESS,
    ADD_PASS_SUBSCRIPTION_ERROR,
    ADD_PASS_SUBSCRIPTION,
} from '../_types/passSubscription';

const initialState: PassSubscriptionState = {
    data: null,
    loading: false,
    error: null,
    isCancelLoading: false,
    cancelError: null,
};

const cancelSubscription = (passData: Array<PassSubscriptionSingle>, passId: number) => {
    const updatedPassData = passData?.map((pass) => {
        if (pass.passId === passId) {
            return { ...pass, subscribed: false };
        }
        return pass;
    });
    return updatedPassData;
};

export default (state = initialState, action: PassSubscriptionAction): PassSubscriptionState => {
    switch (action.type) {
        case GET_PASS_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case GET_PASS_SUBSCRIPTION:
            return {
                ...state,
                loading: true,
            };
        case GET_PASS_SUBSCRIPTION_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case ADD_PASS_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case ADD_PASS_SUBSCRIPTION:
            return {
                ...state,
                loading: true,
            };
        case ADD_PASS_SUBSCRIPTION_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case CANCEL_PASS_SUBSCRIPTION:
            return {
                ...state,
                cancelError: null,
                isCancelLoading: true,
            };
        case CANCEL_PASS_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                data: state.data && cancelSubscription(state?.data, action.payload.passId),
                isCancelLoading: false,
            };
        case CANCEL_PASS_SUBSCRIPTION_ERROR:
            return {
                ...state,
                cancelError: action.payload,
                isCancelLoading: false,
            };
        default:
            return state;
    }
};
