export const GET_REWARD_CODE = 'GET_REWARD_CODE';
export const GET_REWARD_CODE_SUCCESS = 'GET_REWARD_CODE_SUCCESS';
export const GET_REWARD_CODE_ERROR = 'GET_REWARD_CODE_ERROR';

export interface RewardCodeRequest {
    rewardCode: string;
}

export interface RewardCode {
    ammount?: number;
    code?: string;
    description?: string;
    endDate?: unknown;
    id: number;
    pogoseatLiability?: boolean;
    redeemableCount?: number;
    salesChannelId: number;
    startDate?: unknown;
    status?: boolean;
    type?: string;
}

export interface RewardCodeError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface RewardCodeState {
    data: RewardCode | null;
    loading: boolean;
    error: RewardCodeError | null;
}

export interface GetRewardCodeAction {
    type: typeof GET_REWARD_CODE;
}

export interface GetRewardCodeSuccessAction {
    type: typeof GET_REWARD_CODE_SUCCESS;
    payload: RewardCode;
}

export interface GetRewardCodeErrorAction {
    type: typeof GET_REWARD_CODE_ERROR;
    payload: RewardCodeError;
}

export type RewardCodeAction = GetRewardCodeAction | GetRewardCodeSuccessAction | GetRewardCodeErrorAction;
