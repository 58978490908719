export const GET_PAYMENT_DETAILS = 'GET_PAYMENT_DETAILS';
export const GET_PAYMENT_DETAILS_INITIAL = 'GET_PAYMENT_DETAILS_INITIAL';
export const GET_PAYMENT_DETAILS_SUCCESS = 'GET_PAYMENT_DETAILS_SUCCESS';
export const GET_PAYMENT_DETAILS_ERROR = 'GET_PAYMENT_DETAILS_ERROR';

export interface PaymentDetailsRequest {
    userId: string;
    salesChannelId: number;
}

export interface PaymentDetails {
    paymentToken: string;
    type: string;
    last4: string;
    expMonth: number;
    expYear: number;
}

export interface PaymentDetailsError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface PaymentDetailsState {
    data: PaymentDetails | null;
    loading: boolean;
    error: PaymentDetailsError | null;
}

export interface GetPaymentDetailsAction {
    type: typeof GET_PAYMENT_DETAILS;
}

export interface GetPaymentDetailsInitialAction {
    type: typeof GET_PAYMENT_DETAILS_INITIAL;
}

export interface GetPaymentDetailsSuccessAction {
    type: typeof GET_PAYMENT_DETAILS_SUCCESS;
    payload: PaymentDetails;
}

export interface GetPaymentDetailsErrorAction {
    type: typeof GET_PAYMENT_DETAILS_ERROR;
    payload: PaymentDetailsError;
}

export type PaymentDetailsAction =
    | GetPaymentDetailsAction
    | GetPaymentDetailsInitialAction
    | GetPaymentDetailsSuccessAction
    | GetPaymentDetailsErrorAction;
