export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_INITIAL = 'FORGOT_PASSWORD_INITIAL';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export interface ForgotPasswordRequest {
    salesChannelId: number;
    email: string;
}

export interface ForgotPassword {
    email: string;
    status?: string;
    _id: string;
    reject_reason?: unknown;
}

export interface ForgotPasswordError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface ForgotPasswordState {
    data: Array<ForgotPassword> | null;
    loading: boolean;
    error: ForgotPasswordError | null;
}

export interface ForgotPasswordAction {
    type: typeof FORGOT_PASSWORD;
}

export interface ForgotPasswordInitialAction {
    type: typeof FORGOT_PASSWORD_INITIAL;
}

export interface ForgotPasswordSuccessAction {
    type: typeof FORGOT_PASSWORD_SUCCESS;
    payload: Array<ForgotPassword>;
}

export interface ForgotPasswordErrorAction {
    type: typeof FORGOT_PASSWORD_ERROR;
    payload: ForgotPasswordError;
}

export type ForgotPasswordOptionsAction =
    | ForgotPasswordAction
    | ForgotPasswordSuccessAction
    | ForgotPasswordInitialAction
    | ForgotPasswordErrorAction;
