export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR';

export interface SetPasswordRequest {
    password: string;
    passwordConfirm: string;
    token: string;
}

export interface SetPassword {
    firstName: string;
    lastName: string;
    email: string;
    language: string;
    phone: string;
    countryCode: number;
    paymentToken: string;
    seatPreference: unknown;
    signupToken: string;
    salesChannelId: number;
    completedAt: string;
    createdAt: string;
    subscribed: boolean;
    additionalData: string;
    rewardCode: unknown;
    shortCodeSignup: boolean;
    source: string;
    campaign: string;
    isPaid: boolean;
    isClaimed: boolean;
    emailToken: string;
    id: string;
}

export interface SetPasswordError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface SetPasswordState {
    data: SetPassword | null;
    loading: boolean;
    error: SetPasswordError | null;
}

export interface SetPasswordAction {
    type: typeof SET_PASSWORD;
}

export interface SetPasswordSuccessAction {
    type: typeof SET_PASSWORD_SUCCESS;
    payload: SetPassword;
}

export interface SetPasswordErrorAction {
    type: typeof SET_PASSWORD_ERROR;
    payload: SetPasswordError;
}

export type SetPasswordOptionsAction = SetPasswordAction | SetPasswordSuccessAction | SetPasswordErrorAction;
