export const UPDATE_CONTACT_INFO_CONFIRM = 'UPDATE_CONTACT_INFO_CONFIRM';
export const UPDATE_CONTACT_INFO_CONFIRM_INITIAL = 'UPDATE_CONTACT_INFO_CONFIRM_INITIAL';
export const UPDATE_CONTACT_INFO_CONFIRM_SUCCESS = 'UPDATE_CONTACT_INFO_CONFIRM_SUCCESS';
export const UPDATE_CONTACT_INFO_CONFIRM_ERROR = 'UPDATE_CONTACT_INFO_CONFIRM_ERROR';

export interface UpdateContactInfoConfirmRequest {
    token: string;
    id: string;
    salesChannelId: number;
}

export interface UpdateContactInfoConfirm {
    status: string;
}

export interface UpdateContactInfoConfirmError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface UpdateContactInfoConfirmState {
    data: Array<UpdateContactInfoConfirm> | null;
    loading: boolean;
    error: UpdateContactInfoConfirmError | null;
}

export interface UpdateContactInfoConfirmAction {
    type: typeof UPDATE_CONTACT_INFO_CONFIRM;
}

export interface UpdateContactInfoConfirmInitialAction {
    type: typeof UPDATE_CONTACT_INFO_CONFIRM_INITIAL;
}

export interface UpdateContactInfoConfirmSuccessAction {
    type: typeof UPDATE_CONTACT_INFO_CONFIRM_SUCCESS;
    payload: Array<UpdateContactInfoConfirm>;
}

export interface UpdateContactInfoConfirmErrorAction {
    type: typeof UPDATE_CONTACT_INFO_CONFIRM_ERROR;
    payload: UpdateContactInfoConfirmError;
}

export type UpdateContactInfoConfirmOptionsAction =
    | UpdateContactInfoConfirmAction
    | UpdateContactInfoConfirmSuccessAction
    | UpdateContactInfoConfirmInitialAction
    | UpdateContactInfoConfirmErrorAction;
