import {
    LoginAction,
    LoginError,
    LoginResponse,
    LOGIN_ERROR,
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_INITIAL,
} from '../_types/login';

export const setLoginLoading = (): LoginAction => {
    return {
        type: LOGIN,
    };
};

export const setLoginInitial = (): LoginAction => {
    return {
        type: LOGIN_INITIAL,
    };
};

export const setLoginError = (err: LoginError): LoginAction => {
    return {
        type: LOGIN_ERROR,
        payload: err,
    };
};

export const setLoginSuccess = (data: LoginResponse): LoginAction => {
    return {
        type: LOGIN_SUCCESS,
        payload: data,
    };
};
