import {
    UserAccountAction,
    SetUserAccountInitialAction,
    USER_ACCOUNT_INITIAL,
    GET_USER_ACCOUNT,
    UserAccount,
    GET_USER_ACCOUNT_ERROR,
    GET_USER_ACCOUNT_SUCCESS,
    UserAccountError,
    USER_ACCOUNT_UPDATE,
    USER_ACCOUNT_UPDATE_ERROR,
    USER_ACCOUNT_UPDATE_SUCCESS,
} from '../_types/userAccount';

export const setUserAccountInitial = (): SetUserAccountInitialAction => {
    return {
        type: USER_ACCOUNT_INITIAL,
    };
};

export const setUserAccountLoading = (): UserAccountAction => {
    return {
        type: GET_USER_ACCOUNT,
    };
};

export const setUserAccountError = (err: UserAccountError): UserAccountAction => {
    return {
        type: GET_USER_ACCOUNT_ERROR,
        payload: err,
    };
};

export const setUserAccountSuccess = (data: UserAccount): UserAccountAction => {
    return {
        type: GET_USER_ACCOUNT_SUCCESS,
        payload: data,
    };
};

export const setUserAccountUpdateLoading = (): UserAccountAction => {
    return {
        type: USER_ACCOUNT_UPDATE,
    };
};

export const setUserAccountUpdateError = (err: UserAccountError): UserAccountAction => {
    return {
        type: USER_ACCOUNT_UPDATE_ERROR,
        payload: err,
    };
};

export const setUserAccountUpdateSuccess = (data: UserAccount): UserAccountAction => {
    return {
        type: USER_ACCOUNT_UPDATE_SUCCESS,
        payload: data,
    };
};
