import {
    GET_REWARD_CODE,
    GET_REWARD_CODE_ERROR,
    GET_REWARD_CODE_SUCCESS,
    RewardCodeAction,
    RewardCodeState,
} from '../_types/rewardCode';

const initialState: RewardCodeState = {
    data: null,
    loading: false,
    error: null,
};

export default (state = initialState, action: RewardCodeAction): RewardCodeState => {
    switch (action.type) {
        case GET_REWARD_CODE_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case GET_REWARD_CODE:
            return {
                ...state,
                loading: true,
            };
        case GET_REWARD_CODE_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
