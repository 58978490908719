import {
    UPDATE_PAYMENT_DETAILS,
    UPDATE_PAYMENT_DETAILS_ERROR,
    UPDATE_PAYMENT_DETAILS_SUCCESS,
    UPDATE_PAYMENT_DETAILS_INITIAL,
    UpdatePaymentAction,
    UpdatePaymentError,
} from '../_types/updatePayment';

export const setUpdatePaymentInitial = (): UpdatePaymentAction => {
    return {
        type: UPDATE_PAYMENT_DETAILS_INITIAL,
    };
};

export const setUpdatePaymentLoading = (): UpdatePaymentAction => {
    return {
        type: UPDATE_PAYMENT_DETAILS,
    };
};

export const setUpdatePaymentError = (err: UpdatePaymentError): UpdatePaymentAction => {
    return {
        type: UPDATE_PAYMENT_DETAILS_ERROR,
        payload: err,
    };
};

export const setUpdatePaymentSuccess = (data: unknown): UpdatePaymentAction => {
    return {
        type: UPDATE_PAYMENT_DETAILS_SUCCESS,
        payload: data,
    };
};
