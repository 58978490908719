import { Suspense, useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import moment from 'moment-timezone';

import './App.scss';

import { setSalesChannelLoading } from './_actions/salesChannel.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { checkoutUiUrl, getChannelByUrl } from './config';
import { setUserAccountLoading } from './_actions/userAccount.actions';
import { getUserAccount } from './_services/userAccount.service';
import CookieConsentComponent from './_components/CookieConsentComponent';
import { setUpcomingEventsLoading } from './_actions/upcomingEvents.actions';
import { getUpcomingEvents } from './_services/upcomingEvents.service';
import { getSalesChannelByUrl } from './_services/salesChannel.service';
import { useQueryparam } from './_hooks/useQueryparam';
import Loader from './_components/Loader/Loader';
import { isErrorOnFetchSalesChannelSelector, userAccountSelector } from './_selectors';
import Routes from './Routes';
import NoErrorLoader from './_components/NoErrorLoader/NoErrorLoader';
import { useIsTicketmaster } from './_hooks/useIsTicketmaster';
import { useIsOkta } from './_hooks/useIsOkta';
import { useIsTextBuy } from './_hooks/useIsTextBuy';
import { useSalesChannel } from './_hooks/useSalesChannel';
import { useIsLoggedIn } from './_hooks/useIsLoggedIn';
import { useIsSeatGeek } from './_hooks/useIsSeatGeek';

const App: React.FC = () => {
    // NOTE: rockets.pogoseat.com: THIS VALUE IS HARDCODED FOR TESTING PURPOSE in localhost
    // const locationHost = 'rockets.pogoseat.com';
    const locationHost = window.location.host;
    const { isDemo, fullName, googleAnalyticsId } = getChannelByUrl(locationHost);
    const isEuropianTimeZone = moment.tz.guess().startsWith('Europe');
    const isUserLoggedIn = useIsLoggedIn();
    const dispatch = useDispatch();
    const query = useQueryparam();
    const [stripePromise] = useState(() =>
        loadStripe((!isDemo ? process.env.REACT_APP_STRIPE_KEY_LIVE : process.env.REACT_APP_STRIPE_KEY_TEST) || ''),
    );

    const isTextBuy = useIsTextBuy();
    const isTicketmaster = useIsTicketmaster();
    const isOkta = useIsOkta();
    const isSeatGeek = useIsSeatGeek();

    const isErrorOnFetchSalesChannel = useSelector(isErrorOnFetchSalesChannelSelector);
    const { textBuyUI, salesChannelId } = useSalesChannel();
    const user = useSelector(userAccountSelector).data;
    const isRegisteredUser = user && user.paymentToken && user.completedAt;
    useEffect(() => {
        dispatch(setSalesChannelLoading());
        dispatch(getSalesChannelByUrl({ salesChannelUrl: locationHost }));
        if (window.location.hash && window.location.hash.includes('confirmemail')) {
            window.location.href = window.location.href.replace('register#', '');
        } // redirect to confirm page
        if (window.location.href.includes('pass#confirmemail')) {
            window.location.href = window.location.href.replace('pass#confirmemail', 'pass/confirmemail');
        } // fix wrong confirmemail url in the emails
    }, []);

    useEffect(() => {
        if (salesChannelId) {
            dispatch(setUpcomingEventsLoading());
            dispatch(getUpcomingEvents({ salesChannelId: salesChannelId }));
        }
    }, [salesChannelId]);

    useEffect(() => {
        if (isUserLoggedIn && textBuyUI && !user) {
            sessionStorage.setItem('loggedIn', 'true');
            dispatch(setUserAccountLoading());
            dispatch(getUserAccount({ signupToken: sessionStorage.getItem('signupToken') || '' }, salesChannelId));
        }
    }, [isUserLoggedIn, textBuyUI]);

    // store queryParams to sessionStorage
    ['referrer', 'source', 'campaign', 'serviceToken', 'channel'].forEach((param) => {
        const queryParamValue = query.get(param);
        if (queryParamValue) {
            sessionStorage.setItem(param, queryParamValue);
        }
        const currentValue = sessionStorage.getItem(param);
        if (!currentValue && !queryParamValue && param === 'source') {
            if ([141, 145].includes(salesChannelId)) {
                sessionStorage.setItem(param, 'studentrushca');
            }
            if ([126, 150, 148].includes(salesChannelId)) {
                sessionStorage.setItem(param, 'lastminute');
            }
            if ([134, 140, 138, 147].includes(salesChannelId)) {
                sessionStorage.setItem(param, 'studentrush');
            }
        }

        if (!currentValue && !queryParamValue && param === 'campaign') {
            if ([138, 147, 149, 150].includes(salesChannelId)) {
                sessionStorage.setItem(param, 'season23-24');
            }
            if ([134, 145, 148].includes(salesChannelId)) {
                sessionStorage.setItem(param, 'web');
            }
        }
    });

    const referrer = sessionStorage.getItem('referrer');
    const oneSessionId = sessionStorage.getItem('oneSessionId');
    if (referrer && oneSessionId && salesChannelId) {
        sessionStorage.removeItem('referrer');
        sessionStorage.removeItem('oneSessionId');
        switch (referrer) {
            case 'checkout-ui':
                window.location.href = `${checkoutUiUrl}/bid/${salesChannelId}/landing?oneSessionId=${oneSessionId}`;
                break;
            case 'checkout-ui-marketplace':
                if (locationHost === 'psulogin.pogoseat.com') {
                    window.location.href = `https://psu.pogoseat.com/?oneSessionId=${oneSessionId}`;
                } else {
                    window.location.href = `https://psuticketsqa.pogoseat.com/?oneSessionId=${oneSessionId}`;
                }

                break;
            default:
                break;
        }
    }

    if (isErrorOnFetchSalesChannel) {
        return <Loader message="Failed to fetch details for salesChannelId!" />;
    }

    if (isTextBuy === null || isTicketmaster === null || isOkta === null || isSeatGeek === null) {
        return (
            <NoErrorLoader
                loading={isTextBuy === null || isTicketmaster === null || isOkta === null || isSeatGeek === null}
            />
        );
    }

    return (
        <div className="App">
            {isTextBuy && (
                <HelmetProvider>
                    <Helmet>
                        <title>{`Sign up with your mobile phone number and we'll text you when tickets are available.`}</title>
                        <meta
                            name="description"
                            content={`The ${fullName} have teamed up with Pogoseat to give fans exclusive access to buying tickets. All it takes is a simple text message to secure your seats!`}
                        />
                        {/* Global site tag (gtag.js) - Google Analytics  */}
                        {googleAnalyticsId && (
                            <script
                                async
                                type={isEuropianTimeZone ? 'text/plain' : 'text/javascript'}
                                data-cookiecategory="analytics"
                                src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
                            ></script>
                        )}
                        <script
                            type={isEuropianTimeZone ? 'text/plain' : 'text/javascript'}
                            data-cookiecategory="analytics"
                        >
                            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          window.gtag = gtag;
          gtag('js', new Date());
          gtag('config', "${googleAnalyticsId}");
        `}
                        </script>
                    </Helmet>
                </HelmetProvider>
            )}

            <Suspense fallback={<Loader />}>
                <Elements stripe={stripePromise}>
                    {salesChannelId ? (
                        <Routes
                            loggedIn={isUserLoggedIn}
                            isTextBuy={!!isTextBuy}
                            isTicketmaster={!!isTicketmaster}
                            isOkta={!!isOkta}
                            isSeatGeek={!!isSeatGeek}
                            isRegisteredUser={!!isRegisteredUser}
                        />
                    ) : (
                        <Loader />
                    )}
                </Elements>

                {isEuropianTimeZone && <CookieConsentComponent />}
            </Suspense>
        </div>
    );
};

export default App;
