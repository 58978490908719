import {
    UpdateContactInfoConfirmOptionsAction,
    UpdateContactInfoConfirmState,
    UPDATE_CONTACT_INFO_CONFIRM,
    UPDATE_CONTACT_INFO_CONFIRM_ERROR,
    UPDATE_CONTACT_INFO_CONFIRM_INITIAL,
    UPDATE_CONTACT_INFO_CONFIRM_SUCCESS,
} from '../_types/updateContactInfoConfirm';

const initialState: UpdateContactInfoConfirmState = {
    data: null,
    loading: false,
    error: null,
};

export default (state = initialState, action: UpdateContactInfoConfirmOptionsAction): UpdateContactInfoConfirmState => {
    switch (action.type) {
        case UPDATE_CONTACT_INFO_CONFIRM_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case UPDATE_CONTACT_INFO_CONFIRM:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_CONTACT_INFO_CONFIRM_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case UPDATE_CONTACT_INFO_CONFIRM_INITIAL:
            return initialState;
        default:
            return state;
    }
};
