import { EventSingle } from './event';

export const GET_PASS_SETTINGS = 'GET_PASS_SETTINGS';
export const GET_PASS_SETTINGS_SUCCESS = 'GET_PASS_SETTINGS_SUCCESS';
export const GET_PASS_SETTINGS_ERROR = 'GET_PASS_SETTINGS_ERROR';

export interface PassSettingsRequest {
    salesChannelId: number;
}

export interface PassSettingsSingle {
    id: number;
    salesChannelId?: number;
    amount: string;
    currency?: string;
    title?: string;
    description?: string;
    startDate?: unknown;
    endDate?: unknown;
    isEnabled?: boolean;
    allowMultiple?: boolean;
    quantity?: number;
    stripeProductId?: string;
    stripePriceId?: string;
    createdAt?: unknown;
    updatedAt?: unknown;
    created?: unknown;
    updated?: unknown;
    events: EventSingle[];
}

export type PassSettingsWithSeletectQty = PassSettingsSingle & { currentQty: number };

export interface PassSettingsError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface PassSettingsState {
    data: Array<PassSettingsSingle> | null;
    loading: boolean;
    error: PassSettingsError | null;
}

export interface GetPassSettingsAction {
    type: typeof GET_PASS_SETTINGS;
}

export interface GetPassSettingsSuccessAction {
    type: typeof GET_PASS_SETTINGS_SUCCESS;
    payload: Array<PassSettingsSingle>;
}

export interface GetPassSettingsErrorAction {
    type: typeof GET_PASS_SETTINGS_ERROR;
    payload: PassSettingsError;
}

export type PassSettingsAction = GetPassSettingsAction | GetPassSettingsSuccessAction | GetPassSettingsErrorAction;
