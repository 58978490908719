import {
    GET_SALES_CHANNEL,
    GET_SALES_CHANNEL_ERROR,
    GET_SALES_CHANNEL_SUCCESS,
    SalesChannel,
    SalesChannelAction,
    SalesChannelError,
} from '../_types/salesChannel';

export const setSalesChannelLoading = (): SalesChannelAction => {
    return {
        type: GET_SALES_CHANNEL,
    };
};

export const setSalesChannelError = (err: SalesChannelError): SalesChannelAction => {
    return {
        type: GET_SALES_CHANNEL_ERROR,
        payload: err,
    };
};

export const setSalesChannelSuccess = (data: SalesChannel): SalesChannelAction => {
    return {
        type: GET_SALES_CHANNEL_SUCCESS,
        payload: data,
    };
};
