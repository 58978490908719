import {
    SavePasswordOptionsAction,
    SavePasswordState,
    SAVE_PASSWORD,
    SAVE_PASSWORD_ERROR,
    SAVE_PASSWORD_INITIAL,
    SAVE_PASSWORD_SUCCESS,
} from '../_types/savePassword';

const initialState: SavePasswordState = {
    data: null,
    loading: false,
    error: null,
};

export default (state = initialState, action: SavePasswordOptionsAction): SavePasswordState => {
    switch (action.type) {
        case SAVE_PASSWORD_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case SAVE_PASSWORD:
            return {
                ...state,
                loading: true,
            };
        case SAVE_PASSWORD_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case SAVE_PASSWORD_INITIAL:
            return initialState;
        default:
            return state;
    }
};
