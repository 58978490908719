import {
    NextSignupFlowMessageError,
    NextSignupFlowMessageState,
    POST_NEXT_SIGNUP_FLOW_MESSAGE,
    POST_NEXT_SIGNUP_FLOW_MESSAGE_ERROR,
    POST_NEXT_SIGNUP_FLOW_MESSAGE_SUCCESS,
    SignupFlowMessageAction,
} from '../_types/nextSignupFlowMessage';

const initialState: NextSignupFlowMessageState = {
    data: null,
    loading: false,
    error: null,
};

export default (state = initialState, action: SignupFlowMessageAction): NextSignupFlowMessageState => {
    switch (action.type) {
        case POST_NEXT_SIGNUP_FLOW_MESSAGE_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: {} as NextSignupFlowMessageError,
            };
        case POST_NEXT_SIGNUP_FLOW_MESSAGE:
            return {
                ...state,
                loading: true,
            };
        case POST_NEXT_SIGNUP_FLOW_MESSAGE_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
