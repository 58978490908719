import {
    GET_PASS_OFFERS,
    GET_PASS_OFFERS_ERROR,
    GET_PASS_OFFERS_SUCCESS,
    ACCEPT_PASS_OFFERS_SUCCESS,
    PassOffersAction,
    PassOffersState,
    PassOffersSingle,
    ACCEPT_PASS_OFFERS_ERROR,
} from '../_types/passOffers';

const initialState: PassOffersState = {
    data: null,
    loading: false,
    error: null,
};

export default (state = initialState, action: PassOffersAction): PassOffersState => {
    switch (action.type) {
        case GET_PASS_OFFERS_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case GET_PASS_OFFERS:
            return {
                ...state,
                loading: true,
            };
        case GET_PASS_OFFERS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case ACCEPT_PASS_OFFERS_SUCCESS:
            return {
                ...state,
                data: updateEventWithAcceptPass(state, action.payload.eventId),
                error: null,
            };
        case ACCEPT_PASS_OFFERS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

const updateEventWithAcceptPass = (state: PassOffersState, eventId: number): PassOffersSingle[] | null => {
    if (state.data === null) return null;
    return state.data.map((data) => {
        if (data.id === eventId) {
            return { ...data, isOffersAccepted: true };
        }
        return data;
    });
};
