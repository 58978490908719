import {
    ForgotPasswordOptionsAction,
    ForgotPasswordState,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_ERROR,
    FORGOT_PASSWORD_INITIAL,
    FORGOT_PASSWORD_SUCCESS,
} from '../_types/forgotPassword';

const initialState: ForgotPasswordState = {
    data: null,
    loading: false,
    error: null,
};

export default (state = initialState, action: ForgotPasswordOptionsAction): ForgotPasswordState => {
    switch (action.type) {
        case FORGOT_PASSWORD_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case FORGOT_PASSWORD:
            return {
                ...state,
                loading: true,
            };
        case FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case FORGOT_PASSWORD_INITIAL:
            return initialState;
        default:
            return state;
    }
};
