export const GET_INVENTORY = 'GET_INVENTORY';
export const GET_INVENTORY_SUCCESS = 'GET_INVENTORY_SUCCESS';
export const GET_INVENTORY_ERROR = 'GET_INVENTORY_ERROR';

export interface InventoryRequest {
    salesChannelId: number;
    user_email: string;
    is_text_buy: boolean;
}

export interface EventDetails {
    sales_channel_id: number;
    event_id: string;
    title: string;
    venue: string;
    time: string;
    timezone: string;
    category: string;
    league: string;
    avfms_sport: string;
    security_phrase: string;
    rewards: string;
    price_rules: string;
    logo_url: string;
    away_team_logo_url: string;
    background_image_url: string;
    seat_upgrade_background_image_url: unknown;
    status: number;
    id: number;
}

export interface PaymentMethod {
    type: string;
    processor: string;
    currency: string;
    amount: number;
    conversion_rate: number;
    currency_amount: number;
}

export interface InventoryItem {
    api_key: string;
    availability_end_time: number;
    availability_start_time: number;
    availability_time: string;
    currency_type: string;
    destination: {
        api_keys: Array<string>;
    };
    event_details: EventDetails;
    event_id: string;
    face_value: number;
    hydrated: boolean;
    is_demo: number;
    last_modified: string;
    last_notified: string;
    origin: {
        api_key: string;
        time: string;
    };
    price: number;
    price_less_barcode: number;
    purchased: {
        purchase_id: string;
        user: string;
        date: string;
        quantity: number;
        stripe_data: {
            id: string;
            object: string;
            amount: number;
            amount_refunded: number;
            application: unknown;
            application_fee: unknown;
            balance_transaction: string;
            captured: boolean;
            created: number;
            currency: string;
            customer: string;
            description: string;
            destination: unknown;
            dispute: unknown;
            failure_code: unknown;
            failure_message: unknown;
            fraud_details: unknown;
            invoice: unknown;
            livemode: boolean;
            metadata: {
                event_id: string;
                sales_channel_id: string;
            };
            on_behalf_of: unknown;
            order: unknown;
            outcome: {
                network_status: string;
                reason: unknown;
                risk_level: string;
                seller_message: string;
                type: string;
            };
            paid: boolean;
            receipt_email: unknown;
            receipt_number: unknown;
            refunded: boolean;
            refunds: {
                object: string;
                data: Array<unknown>;
                has_more: boolean;
                total_count: number;
                url: string;
            };
            review: unknown;
            shipping: unknown;
            source: {
                id: string;
                object: string;
                address_city: unknown;
                address_country: unknown;
                address_line1: unknown;
                address_line1_check: unknown;
                address_line2: unknown;
                address_state: unknown;
                address_zip: unknown;
                address_zip_check: unknown;
                brand: string;
                country: string;
                customer: string;
                cvc_check: unknown;
                dynamic_last4: unknown;
                exp_month: number;
                exp_year: number;
                fingerprint: string;
                funding: string;
                last4: string;
                metadata: unknown;
                name: unknown;
                tokenization_method: unknown;
            };
            source_transfer: unknown;
            statement_descriptor: unknown;
            status: string;
            transfer_group: unknown;
        };
        additional_data: {
            claim_tx: [
                {
                    op: string;
                    purchase: {
                        token: string;
                        target: {
                            email: string;
                            phone: string;
                            first: string;
                            last: string;
                        };
                    };
                },
            ];
            barcode: unknown;
            expects_barcode: unknown;
            firstName: string;
            lastName: string;
            email: string;
            flash_seat: unknown;
            order_id: unknown;
            is_text_buy: boolean;
            sales_notification_datetime: string;
        };
        reward: {
            code: string;
            valid: boolean;
            value: number;
            reward_value: number;
            pogoseat_liability: number;
            client_liability: number;
        };
        old_barcode_data: Array<unknown>;
        purchase_token: string;
        purchased_price: number;
        price_info: {
            original_price: number;
            price: number;
            price_less_barcode: number;
            reward: {
                code: string;
                valid: boolean;
                value: number;
                reward_value: number;
                pogoseat_liability: number;
                client_liability: number;
            };
            barcode_token: {
                valid: boolean;
                value: number;
            };
            token_info: {
                token_valid: boolean;
                token_value: number;
            };
            payment_methods: Array<PaymentMethod>;
            revenue_share: {
                sales_channel_revshare: {
                    amount: number;
                    inventory_type: string;
                    payment_type: string;
                };
                client_revshare: {
                    amount: number;
                    inventory_type: string;
                    payment_type: string;
                };
            };
            cc_fee: number;
            client_net_revenue: number;
            sales_channel_net_revenue: number;
        };
    };
    reserved_at: string;
    row: string;
    seat: string;
    section: string;
    template_id: string;
    type: string;
    uuid: string;
}

export interface InventoryError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface InventoryState {
    data: Array<InventoryItem> | null;
    loading: boolean;
    error: InventoryError | null;
}

export interface GetInventoryAction {
    type: typeof GET_INVENTORY;
}

export interface GetInventorySuccessAction {
    type: typeof GET_INVENTORY_SUCCESS;
    payload: Array<InventoryItem>;
}

export interface GetInventoryErrorAction {
    type: typeof GET_INVENTORY_ERROR;
    payload: InventoryError;
}

export type InventoryAction = GetInventoryAction | GetInventorySuccessAction | GetInventoryErrorAction;
