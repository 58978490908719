export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const REGISTER_INITIAL = 'REGISTER_INITIAL';

export interface RegisterRequest {
    countryCode: string;
    email: string;
    firstName: string;
    lastName: string;
    messageChannelType: string;
    optedIn: boolean;
    phone: string;
    salesChannelId?: number;
    serviceToken: string;
    shortCodeSignup: boolean;
    campaign?: string;
    source?: string;
    referrer?: string;
}

export interface RegisterResponse {
    subscribed?: boolean;
    isPaid?: boolean;
    isClaimed?: boolean;
    email?: string;
    phone?: string;
    countryCode?: string;
    firstName?: string;
    lastName?: string;
    shortCodeSignup?: boolean;
    source?: string;
    campaign?: string;
    salesChannelId: number;
    emailToken?: string;
    signupToken?: string;
    createdAt?: string;
    id: string;
}

export interface RegisterError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface RegisterState {
    data: RegisterResponse | null;
    loading: boolean;
    error: RegisterError | null;
}

export interface SetRegisterInitialAction {
    type: typeof REGISTER_INITIAL;
}
export interface RegisterStartAction {
    type: typeof REGISTER;
}

export interface RegisterSuccessAction {
    type: typeof REGISTER_SUCCESS;
    payload: RegisterResponse;
}

export interface RegisterErrorAction {
    type: typeof REGISTER_ERROR;
    payload: RegisterError;
}

export type RegisterAction =
    | RegisterStartAction
    | RegisterSuccessAction
    | RegisterErrorAction
    | SetRegisterInitialAction;
