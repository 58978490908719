import {
    SetPasswordOptionsAction,
    SetPasswordState,
    SET_PASSWORD,
    SET_PASSWORD_ERROR,
    SET_PASSWORD_SUCCESS,
} from '../_types/setPassword';

const initialState: SetPasswordState = {
    data: null,
    loading: false,
    error: null,
};

export default (state = initialState, action: SetPasswordOptionsAction): SetPasswordState => {
    switch (action.type) {
        case SET_PASSWORD_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case SET_PASSWORD:
            return {
                ...state,
                loading: true,
            };
        case SET_PASSWORD_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
