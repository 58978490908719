import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import SideNav from '../../_components/SideNav';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSideNavigation } from '../../_actions/sideNav.actions';
import { GoTriangleLeft } from 'react-icons/go';
import LogOutDialogConfirm from '../../_components/LogOutDialogConfirm';
import { loginSelector, sideNavSelector, userAccountSelector } from '../../_selectors';
import RegistrationConfirmDialog from '../../_components/RegistionConfirmDialog';
import { setUserAccountLoading } from '../../_actions/userAccount.actions';
import { getUserAccount } from '../../_services/userAccount.service';
interface MyAccountPageProps {
    children?: React.ReactNode;
}

const MyAccountPage: React.FC<MyAccountPageProps> = ({ children }) => {
    const dispatch = useDispatch();
    const { data: loginData } = useSelector(loginSelector);
    const { data: userAccountData } = useSelector(userAccountSelector);

    const { open } = useSelector(sideNavSelector);

    useEffect(() => {
        if (loginData && userAccountData && loginData.signupToken !== userAccountData.signupToken) {
            dispatch(setUserAccountLoading());
            dispatch(getUserAccount({ signupToken: loginData.signupToken }, loginData.salesChannelId));
        }
    }, [loginData, userAccountData]);

    return (
        <div className="p-0">
            <Drawer open={open} onClose={() => dispatch(toggleSideNavigation(!open))} direction="left" size={340}>
                <SideNav />
            </Drawer>
            <section className="d-flex flex-column justify-content-center align-items-center">
                <div
                    className="w-100 p-3 bg-dark text-white cursor-pointer"
                    onClick={() => dispatch(toggleSideNavigation(!open))}
                >
                    <GoTriangleLeft className="text-primary" /> Return to menu
                </div>
                <LogOutDialogConfirm />
                <RegistrationConfirmDialog />
                <div className="w-100 flex min-h-screen bg-center bg-cover md:py-4 page-bg">{children}</div>
            </section>
        </div>
    );
};

MyAccountPage.propTypes = {
    children: PropTypes.node,
};

export default MyAccountPage;
