import React, { useEffect } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setRegConfirmDialogConfirmation } from '../../_actions/dialog.actions';
import { useIsTextBuy } from '../../_hooks/useIsTextBuy';
import { dialogSelector } from '../../_selectors';

const RegistrationConfirmDialog: React.FC = () => {
    const history = useHistory();

    const isTextBuy = useIsTextBuy();

    const dispatch = useDispatch();
    const { openRegistraionConfirDialog } = useSelector(dialogSelector);
    const closeModal = () => {
        dispatch(setRegConfirmDialogConfirmation(!openRegistraionConfirDialog));
    };

    // add queryParam registrationSuccess to url for uniquely track in google
    useEffect(() => {
        if (openRegistraionConfirDialog) {
            const queryParam = new URLSearchParams();
            queryParam.set('registrationCompleted', 'true');
            history.replace({
                search: queryParam.toString(),
            });
        }
    }, [openRegistraionConfirDialog]);

    const confirmationText = isTextBuy
        ? `Congratulations! You have successfully registered, we will text you when seats are available!`
        : `Congratulations! You have successfully purchased a Pass! We will send you a
        confirmation email`;
    return (
        <>
            <Modal
                isOpen={openRegistraionConfirDialog}
                ariaHideApp={false}
                className="pass--dialog"
                style={{ overlay: { zIndex: 2 } }}
            >
                <div className="alert col-md-6 offset-md-3 text-center alert-success alert-dismissible" role="alert">
                    <AiFillCheckCircle className="alert-icon text-center mr-2" />
                    {confirmationText}
                    <button
                        type="button"
                        onClick={closeModal}
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </Modal>
        </>
    );
};

export default RegistrationConfirmDialog;
