/**
 * @type {UserConfig}
 */
const pluginConfig = {
    current_lang: 'en',
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false
    test: 'ciao',
    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    languages: {
        en: {
            consent_modal: {
                title: 'We use cookies!',
                description:
                    'Pogoseat, Inc. would like to use our own and third party cookies and similar technologies for statistics and marketing purposes. You can set your preferences by selecting the options below. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all', // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Reject all',
                    role: 'accept_necessary', // 'settings' or 'accept_necessary'
                },
            },
            settings_modal: {
                title: 'Cookie Settings',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                cookie_table_headers: [{ col1: 'Name' }, { col2: 'Domain' }, { col3: 'Expiration' }],
                blocks: [
                    {
                        title: 'Cookie usage 📢',
                        description:
                            'Pogoseat, Inc. would like to use our own and third party cookies and similar technologies for statistics and marketing purposes. You can set your preferences by selecting the options below.',
                    },
                    {
                        title: 'Strictly necessary cookies',
                        description:
                            'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                        cookie_table: [
                            // list of all expected cookies
                            {
                                col1: '^__stripe', // match all cookies starting with "_stripe"
                                col2: 'stripe.com',
                                col3: '1 year',
                                is_regex: true,
                            },
                        ],
                    },
                    {
                        title: 'Performance and Analytics cookies',
                        description:
                            'These cookies allow the website to remember the choices you have made in the past',
                        toggle: {
                            value: 'analytics', // your cookie category
                            enabled: false,
                            readonly: false,
                        },
                        cookie_table: [
                            // list of all expected cookies
                            {
                                col1: '^_ga', // match all cookies starting with "_ga"
                                col2: 'pogoseat.com',
                                col3: '400 days',
                                is_regex: true,
                            },
                        ],
                    },
                    {
                        title: 'More information',
                        description:
                            'For any queries in relation to our policy on cookies and your choices, please contact us at <a class="cc-link" href="mailto:support@pogoseat.com">support@pogoseat.com</a>.',
                    },
                ],
            },
        },
    },
};

export default pluginConfig;
