import { SideNavAction, SideNavState, TOGGLE_SIDENAV } from '../_types/sideNav';

const initialState: SideNavState = {
    open: false,
};

export default (state = initialState, action: SideNavAction): SideNavState => {
    switch (action.type) {
        case TOGGLE_SIDENAV:
            return {
                open: action.payload,
            };
        default:
            return state;
    }
};
