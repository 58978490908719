import { ThunkAction } from 'redux-thunk';
import { configUrl } from '../config';
import { setUpcomingEventsError, setUpcomingEventsSuccess } from '../_actions/upcomingEvents.actions';
import { RootState } from '../_reducers';
import { EventSingle, UpcomingEventsAction, UpcomingEventsError, UpcomingEventsRequest } from '../_types/event';

export const getUpcomingEvents = (
    data: UpcomingEventsRequest,
): ThunkAction<void, RootState, null, UpcomingEventsAction> => {
    const url = `${configUrl}/api/${data.salesChannelId}/upcoming-events`;
    return async (dispatch) => {
        try {
            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!res.ok) {
                const resData: UpcomingEventsError = await res.json();
                dispatch(setUpcomingEventsError(resData));
                return;
            }

            const resData: Array<EventSingle> = await res.json();
            dispatch(setUpcomingEventsSuccess(resData));
        } catch (err) {
            dispatch(setUpcomingEventsError({ message: 'Error occurred!' }));
            throw err;
        }
    };
};
