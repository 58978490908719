import PropTypes from 'prop-types';

interface ButtonSpinnerProps {
    show: boolean;
    buttonLabel: string;
}

const ButtonSpinner: React.FC<ButtonSpinnerProps> = ({ buttonLabel, show }) => {
    return (
        <>
            {!show ? (
                <span>{buttonLabel}</span>
            ) : (
                <div>
                    <span className="spinner-grow spinner-grow-sm mr-2" role="status"></span>
                    <span>Loading...</span>
                </div>
            )}
        </>
    );
};

ButtonSpinner.propTypes = {
    show: PropTypes.bool.isRequired,
    buttonLabel: PropTypes.string.isRequired,
};

export default ButtonSpinner;
