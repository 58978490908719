export const LOGIN = 'LOGIN';
export const LOGIN_INITIAL = 'LOGIN_INITIAL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export interface messageChannel {
    id: number;
    userId: number;
    salesChannelId: number;
    type: string;
    serviceToken: string;
    subscribed: boolean;
    verifiedAt: unknown;
    additionalData: unknown;
    salesChannelMessageChannelId: number;
}

export interface LoginRequest {
    password: string;
    salesChannelId: number;
    username: string;
}

export interface LoginResponse {
    firstName?: string;
    lastName?: string;
    email?: string;
    language?: string;
    phone?: string;
    countryCode?: number;
    paymentToken?: string;
    seatPreference?: unknown;
    signupToken: string;
    salesChannelId: number;
    completedAt?: string;
    createdAt?: string;
    subscribed?: boolean;
    additionalData?: string;
    rewardCode?: string;
    shortCodeSignup?: boolean;
    source?: string;
    campaign?: string;
    isPaid?: boolean;
    isClaimed?: boolean;
    emailToken?: unknown;
    salesChannel?: {
        id?: number;
        name?: string;
        clientId?: number;
        provider?: string;
        logoUrl?: string;
        imageUrls?: string;
        isDemo?: boolean;
        isTextBuy?: boolean;
    };
    resetTokens?: Array<unknown>;
    messageChannels?: Array<messageChannel>;
    id?: string;
}

export interface LoginError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
    error_description?: string;
}

export interface LoginState {
    data: LoginResponse | null;
    loading: boolean;
    error: LoginError | null;
    authorized: boolean;
}

export interface SetLoginAction {
    type: typeof LOGIN;
}

export interface SetLoginInitialAction {
    type: typeof LOGIN_INITIAL;
}

export interface SetLoginSuccessAction {
    type: typeof LOGIN_SUCCESS;
    payload: LoginResponse;
}

export interface SetLoginErrorAction {
    type: typeof LOGIN_ERROR;
    payload: LoginError;
}

export type LoginAction = SetLoginAction | SetLoginInitialAction | SetLoginErrorAction | SetLoginSuccessAction;
