import { ThunkAction } from 'redux-thunk';
import { configUrl } from '../config';
import { setUserAccountError, setUserAccountSuccess } from '../_actions/userAccount.actions';
import { RootState } from '../_reducers';
import { UserAccount, UserAccountAction, UserAccountError, UserAccountRequest } from '../_types/userAccount';

export const getUserAccount = (
    data: UserAccountRequest,
    id: number,
): ThunkAction<void, RootState, null, UserAccountAction> => {
    const url = `${configUrl}/api/${id}/getUserAccount`;
    return async (dispatch) => {
        try {
            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!res.ok) {
                const resData: UserAccountError = await res.json();
                dispatch(setUserAccountError(resData));
                return;
            }

            const resData: UserAccount = await res.json();
            dispatch(setUserAccountSuccess(resData));
        } catch (err) {
            dispatch(setUserAccountError({ message: 'Error occurred!' }));
            throw err;
        }
    };
};
