import { DialogAction, TOGGLE_LOGOUT_DIALOG, TOGGLE_REGISTRATION_CONFIRM_DIALOG } from '../_types/dialog';

export const setLogOutDialogConfirmation = (open: boolean): DialogAction => {
    return {
        type: TOGGLE_LOGOUT_DIALOG,
        payload: open,
    };
};

export const setRegConfirmDialogConfirmation = (open: boolean): DialogAction => {
    return {
        type: TOGGLE_REGISTRATION_CONFIRM_DIALOG,
        payload: open,
    };
};
