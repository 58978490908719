import PropTypes from 'prop-types';
import './index.scss';
import { useSalesChannel } from '../../_hooks/useSalesChannel';

interface TeamLogoProps {
    position?: 'left' | 'right' | 'center';
    positionOnMobile?: 'left' | 'right' | 'center';
    showTeamName?: boolean;
}

const TeamLogo: React.FC<TeamLogoProps> = ({ position, positionOnMobile, showTeamName }) => {
    const { salesChannelUi, textBuyUI } = useSalesChannel();
    const selectedChannelName = salesChannelUi?.name;

    return (
        <>
            <p className={`text-${positionOnMobile} text-lg-${position} my-3`}>
                <img className="teamLogo" src={textBuyUI?.logoUrl} alt={selectedChannelName} />
            </p>
            {showTeamName && <h5 className="card-title">{selectedChannelName}</h5>}
        </>
    );
};

TeamLogo.propTypes = {
    position: PropTypes.oneOf(['left', 'right', 'center']),
    positionOnMobile: PropTypes.oneOf(['left', 'right', 'center']),
    showTeamName: PropTypes.bool,
};

TeamLogo.defaultProps = {
    position: 'left',
    positionOnMobile: 'center',
    showTeamName: false,
};

export default TeamLogo;
