import { ReactElement } from 'react';
import { FaTimes } from 'react-icons/fa';
import ReactModal from 'react-modal';

import FaqNew from '../FaqNew';

type FaqModalProps = {
    isOpen: boolean;
    onRequestClose: () => void;
};

export function FaqModal({ isOpen, onRequestClose }: FaqModalProps): ReactElement {
    return (
        <ReactModal
            isOpen={isOpen}
            contentLabel="FAQ"
            ariaHideApp={false}
            style={modalStyles}
            shouldCloseOnOverlayClick={true}
            onRequestClose={onRequestClose}
            className={'tm-login tm-login-modal-content'}
        >
            <div className={'bg-white max-w-2xl mx-auto md:mt-14 relative tm-modal'}>
                <FaTimes
                    color={'black'}
                    size={22}
                    onClick={onRequestClose}
                    className={'absolute right-2 cursor-pointer mt-2'}
                    style={{ top: '36px', right: '20px', zIndex: 10 }}
                />
                <FaqNew />
            </div>
        </ReactModal>
    );
}

const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,.7)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        outline: 'none',
    },
};
