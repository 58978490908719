import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import dialogReducer from './dialog.reducer';
import forgotPasswordReducer from './forgotPassword.reducer';
import inventoryReducer from './inventory.reducer';

import loginReducer from './login.reducer';
import nextSignupFlowMessageReducer from './nextSignupFlowMessage.reducer';
import passSettingsReducer from './passSettings.reducer';
import passOffersReducer from './passOffers.reducer';
import passSubscriptionRequest from './passSubscription.reducer';
import paymentDetailsReducer from './paymentDetails.reducer';
import registerReducer from './register.reducer';
import rewardCodeReducer from './rewardCode.reducer';
import salesChannelReducer from './salesChannel.reducer';
import setPasswordReducer from './setPassword.reducer';
import confirmEmailReducer from './confirmEmail.reducer';
import sideNavReducer from './sideNav.reducer';
import upcomingEventsReducer from './upcomingEvents.reducer';
import updatePaymentReducer from './updatePayment.reducer';
import deletePaymentCardReducer from './deletePaymentCard.reducer';
import userAcountReducer from './userAcount.reducer';
import userAcountReferralReducer from './userAccountReferral.reducer';
import savePasswordReducer from './savePassword.reducer';
import updateContactInfoReducer from './updateContactInfo.reducer';
import updateContactInfoConfirmReducer from './updateContactInfoConfirm.reducer';

const rootReducer = combineReducers({
    login: loginReducer,
    register: registerReducer,
    savePassword: savePasswordReducer,
    salesChannel: salesChannelReducer,
    nextSignupFlowMessage: nextSignupFlowMessageReducer,
    userAccount: userAcountReducer,
    forgotPassword: forgotPasswordReducer,
    setPassword: setPasswordReducer,
    confirmEmail: confirmEmailReducer,
    rewardCode: rewardCodeReducer,
    sideNav: sideNavReducer,
    dialogs: dialogReducer,
    inventory: inventoryReducer,
    passSettings: passSettingsReducer,
    passOffers: passOffersReducer,
    passSubscription: passSubscriptionRequest,
    upcomingEvents: upcomingEventsReducer,
    paymentDetails: paymentDetailsReducer,
    updatePayment: updatePaymentReducer,
    deletePaymentCard: deletePaymentCardReducer,
    userReferral: userAcountReferralReducer,
    updateContactInfo: updateContactInfoReducer,
    updateContactInfoConfirm: updateContactInfoConfirmReducer,
});

const store = createStore(
    rootReducer,
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
        ? composeWithDevTools(applyMiddleware(thunk))
        : applyMiddleware(thunk),
);

export type RootState = ReturnType<typeof rootReducer>;

export default store;
