import { createSelector } from 'reselect';
import { RootState } from '../_reducers';
import { DialogState } from '../_types/dialog';
import { UpcomingEventsState } from '../_types/event';
import { ForgotPasswordState } from '../_types/forgotPassword';
import { InventoryState } from '../_types/inventory';
import { LoginState } from '../_types/login';
import { PassOffersState } from '../_types/passOffers';
import { PassSettingsState } from '../_types/passSettings';
import { PassSubscriptionState } from '../_types/passSubscription';
import { PaymentDetailsState } from '../_types/paymentDetails';
import { RegisterState } from '../_types/register';
import { SalesChannelState } from '../_types/salesChannel';
import { SavePasswordState } from '../_types/savePassword';
import { SetPasswordState } from '../_types/setPassword';
import { ConfirmEmailState } from '../_types/confirmEmail';
import { SideNavState } from '../_types/sideNav';
import { UpdateContactInfoState } from '../_types/updateContactInfo';
import { UpdateContactInfoConfirmState } from '../_types/updateContactInfoConfirm';
import { UpdatePaymentState } from '../_types/updatePayment';
import { UserAccountState } from '../_types/userAccount';
import { UserAccountReferralState } from '../_types/userAccountReferral';
import { DeletePaymentCardState } from '../_types/deletePaymentCard';

const salesChannelSelector = (state: RootState): SalesChannelState => state.salesChannel;
const userAccountSelector = (state: RootState): UserAccountState => state.userAccount;
const loginSelector = (state: RootState): LoginState => state.login;
const registerSelector = (state: RootState): RegisterState => state.register;
const savePasswordSelector = (state: RootState): SavePasswordState => state.savePassword;
const forgotPasswordSelector = (state: RootState): ForgotPasswordState => state.forgotPassword;
const setPasswordSelector = (state: RootState): SetPasswordState => state.setPassword;
const confirmEmailSelector = (state: RootState): ConfirmEmailState => state.confirmEmail;
const passSettingsSelector = (state: RootState): PassSettingsState => state.passSettings;
const passSubscriptionSelector = (state: RootState): PassSubscriptionState => state.passSubscription;
const passOffersSelector = (state: RootState): PassOffersState => state.passOffers;
const dialogSelector = (state: RootState): DialogState => state.dialogs;
const sideNavSelector = (state: RootState): SideNavState => state.sideNav;
const inventorySelector = (state: RootState): InventoryState => state.inventory;
const paymentDetailsSelector = (state: RootState): PaymentDetailsState => state.paymentDetails;
const updatePaymentSelector = (state: RootState): UpdatePaymentState => state.updatePayment;
const deletePaymentCardSelector = (state: RootState): DeletePaymentCardState => state.deletePaymentCard;
const upcomingEventsSelector = (state: RootState): UpcomingEventsState => state.upcomingEvents;
const userAccountReferralSelector = (state: RootState): UserAccountReferralState => state.userReferral;
const updateContactInfoSelector = (state: RootState): UpdateContactInfoState => state.updateContactInfo;
const updateContactInfoConfirmSelector = (state: RootState): UpdateContactInfoConfirmState =>
    state.updateContactInfoConfirm;

const isErrorOnFetchSalesChannelSelector = createSelector(salesChannelSelector, (salesChannel) => salesChannel.error);

export {
    salesChannelSelector,
    userAccountSelector,
    loginSelector,
    registerSelector,
    savePasswordSelector,
    forgotPasswordSelector,
    setPasswordSelector,
    confirmEmailSelector,
    passSettingsSelector,
    passSubscriptionSelector,
    passOffersSelector,
    dialogSelector,
    sideNavSelector,
    inventorySelector,
    paymentDetailsSelector,
    updatePaymentSelector,
    deletePaymentCardSelector,
    upcomingEventsSelector,
    userAccountReferralSelector,
    updateContactInfoSelector,
    updateContactInfoConfirmSelector,
    isErrorOnFetchSalesChannelSelector,
};
