export const GET_USER_ACCOUNT_REFERRAL = 'GET_USER_ACCOUNT_REFERRAL';
export const GET_USER_ACCOUNT_REFERRAL_SUCCESS = 'GET_USER_ACCOUNT_REFERRAL_SUCCESS';
export const GET_USER_ACCOUNT_REFERRAL_ERROR = 'GET_USER_ACCOUNT_REFERRAL_ERROR';
export const SET_USER_ACCOUNT_REFERRAL = 'SET_USER_ACCOUNT_REFERRAL';
export const SET_USER_ACCOUNT_REFERRAL_SUCCESS = 'SET_USER_ACCOUNT_REFERRAL_SUCCESS';
export const SET_USER_ACCOUNT_REFERRAL_ERROR = 'SET_USER_ACCOUNT_REFERRAL_ERROR';

export interface UserAccountReferralRequest {
    salesChannelId: number;
    userId: string;
}

export interface UserAccountReferral {
    referralUrl: string;
}

export interface SetUserAccountReferralRequest {
    userId: string;
}

export type UserAccountReferralWithSeletectQty = UserAccountReferral & { currentQty: number };

export interface UserAccountReferralError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface UserAccountReferralState {
    data: UserAccountReferral | null;
    loading: boolean;
    error: UserAccountReferralError | null;
}

export interface GetUserAccountReferralAction {
    type: typeof GET_USER_ACCOUNT_REFERRAL;
}

export interface GetUserAccountReferralSuccessAction {
    type: typeof GET_USER_ACCOUNT_REFERRAL_SUCCESS;
    payload: UserAccountReferral;
}

export interface GetUserAccountReferralErrorAction {
    type: typeof GET_USER_ACCOUNT_REFERRAL_ERROR;
    payload: UserAccountReferralError;
}

export interface SetUserAccountReferralAction {
    type: typeof SET_USER_ACCOUNT_REFERRAL;
    payload: SetUserAccountReferralRequest;
}

export interface SetUserAccountReferralSuccessAction {
    type: typeof SET_USER_ACCOUNT_REFERRAL_SUCCESS;
    payload: UserAccountReferral;
}

export interface SetUserAccountReferralErrorAction {
    type: typeof SET_USER_ACCOUNT_REFERRAL_ERROR;
    payload: UserAccountReferralError;
}

export type UserAccountReferralAction =
    | GetUserAccountReferralAction
    | GetUserAccountReferralSuccessAction
    | GetUserAccountReferralErrorAction
    | SetUserAccountReferralAction
    | SetUserAccountReferralSuccessAction
    | SetUserAccountReferralErrorAction;
