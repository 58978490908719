import PropTypes from 'prop-types';
import { FcOk } from 'react-icons/fc';
import './index.scss';

interface PageHeaderProps {
    title: string;
    subTitle?: string;
    checkIcon?: boolean;
    iconComp?: React.ReactNode;
    position?: 'left' | 'right' | 'center';
    verticalSpace?: number;
}

const PageHeader: React.FC<PageHeaderProps> = ({
    title,
    subTitle,
    checkIcon,
    iconComp,
    position = 'left',
    verticalSpace = 2,
}) => {
    return (
        <>
            <h3 className={`text-bold text-${position} my-${verticalSpace} ${checkIcon && 'text-medium'}`}>
                {checkIcon && (
                    <span className="mr-2">
                        <FcOk />
                    </span>
                )}
                {iconComp && <span className="mr-2">{iconComp}</span>}
                {title}
            </h3>
            {subTitle && <p className={`text-${position} text-sm font-medium`}>{subTitle}</p>}
        </>
    );
};

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    checkIcon: PropTypes.bool,
    iconComp: PropTypes.node,
    position: PropTypes.oneOf(['left', 'right', 'center']),
};

export default PageHeader;
