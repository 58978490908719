import { LoginState, LoginAction, LOGIN_SUCCESS, LOGIN, LOGIN_ERROR, LOGIN_INITIAL } from '../_types/login';

const initialState: LoginState = {
    data: null,
    loading: false,
    error: null,
    authorized: false,
};

export default (state = initialState, action: LoginAction): LoginState => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: null,
                authorized: true,
            };
        case LOGIN:
            return {
                ...state,
                loading: true,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
                authorized: false,
            };
        case LOGIN_INITIAL:
            return initialState;
        default:
            return state;
    }
};
