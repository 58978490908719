import React, { useEffect, useState } from 'react';

import { FaqSingle } from '../../_types/faq';
import { useSelector } from 'react-redux';
import { isEmptyArray } from 'formik';
import Text from '../Text';
import { salesChannelSelector } from '../../_selectors';
import { urlify } from '../Faq';
import { getChannelByUrl } from '../../config';

const FaqTailwind: React.FC = () => {
    const { data } = useSelector(salesChannelSelector);
    const salesChannelConfig = getChannelByUrl(window.location.host);
    const source = sessionStorage.getItem('source');

    const sourceSpecificFaqs =
        salesChannelConfig &&
        source &&
        salesChannelConfig.faqOverrides &&
        (source ? salesChannelConfig.faqOverrides[source] : null);

    const [faqs, setFaqs] = useState<Array<FaqSingle>>([]);
    let faqArrayData: Array<FaqSingle> = [];

    useEffect(() => {
        if (data) {
            if (sourceSpecificFaqs) {
                faqArrayData = JSON.parse(sourceSpecificFaqs);
            } else if (data.faqPass) {
                faqArrayData = JSON.parse(data.faqPass);
            } else {
                faqArrayData = JSON.parse(data.faqDefault);
            }

            faqArrayData?.forEach((faq, index) => {
                faq.open = true;
                faq.id = index;
            });
            setFaqs(faqArrayData);
        }
    }, [data, sourceSpecificFaqs]);

    const toggleFaq = (faq: FaqSingle) => {
        if (faqs) {
            const result = faqs.map((el) => (el.id === faq.id ? { ...el, open: !faq.open } : el));

            setFaqs(result);
        }
    };

    return (
        <>
            <div className=" justify-content-center px-3 px-sm-5 py-6 faq-wrapper">
                <div className="border-bottom col-12">
                    <h2 className={'mb-4 text-xl font-medium text-base'} style={{ fontFamily: 'Quicksand' }}>
                        FAQ
                    </h2>
                    {isEmptyArray(faqs) && <Text message="FAQs couldn't be retrieved!" type="error" />}
                </div>
                {!isEmptyArray(faqs) &&
                    faqs.map((faq) => (
                        <div className="col-12 align-self-center py-3 border-bottom" key={faq.id}>
                            <div
                                className="flex  faq__question-wrapper w-full justify-content-between"
                                onClick={() => toggleFaq(faq)}
                            >
                                <span className="font-bold text-sm" aria-expanded={faq.open}>
                                    {faq.question}
                                </span>

                                <span className="faq__question-icon">
                                    <div>
                                        <svg
                                            style={faq.open ? { transform: 'scale(-1)' } : {}}
                                            width="10"
                                            height="6"
                                            viewBox="0 0 10 6"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9 1L5 5L1 1"
                                                stroke="#161616"
                                                strokeWidth="2"
                                                strokeMiterlimit="10"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                </span>
                            </div>
                            <div className={`mt-2 text-sm ${faq.open ? 'hidden' : 'block'}`}>{urlify(faq.answer)}</div>
                        </div>
                    ))}
            </div>
        </>
    );
};

export default FaqTailwind;
