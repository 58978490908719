import {
    GET_SALES_CHANNEL,
    GET_SALES_CHANNEL_ERROR,
    GET_SALES_CHANNEL_SUCCESS,
    SalesChannelAction,
    SalesChannelState,
} from '../_types/salesChannel';

const initialState: SalesChannelState = {
    data: null,
    loading: false,
    error: null,
};

export default (state = initialState, action: SalesChannelAction): SalesChannelState => {
    switch (action.type) {
        case GET_SALES_CHANNEL_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case GET_SALES_CHANNEL:
            return {
                ...state,
                loading: true,
            };
        case GET_SALES_CHANNEL_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
