import { SalesChannel } from './salesChannel';

export const GET_UPCOMING_EVENTS = 'GET_UPCOMING_EVENTS';
export const GET_UPCOMING_EVENTS_SUCCESS = 'GET_UPCOMING_EVENTS_SUCCESS';
export const GET_UPCOMING_EVENTS_ERROR = 'GET_UPCOMING_EVENTS_ERROR';

export interface UpcomingEventsRequest {
    salesChannelId: number;
}

export interface EventSingle {
    id: number;
    eventId: string;
    title: string;
    venue: string;
    time: string;
    timezone: string;
    securityPhrase: string;
    rewards: unknown;
    price_rules: unknown;
    category: string;
    league: string;
    avfmsSport: string;
    logoUrl: string;
    backgroundImageUrl: string;
    seatUpgradeBackgroundImageUrl: string;
    awayTeamLogoUrl: string;
    status: number;
    salesChannelId: number;
    isDefaultOfferEnabled: boolean;
    salesChannel: SalesChannel;
}

export interface UpcomingEventsError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface UpcomingEventsState {
    data: Array<EventSingle> | null;
    loading: boolean;
    error: UpcomingEventsError | null;
}

export interface GetUpcomingEventsAction {
    type: typeof GET_UPCOMING_EVENTS;
}

export interface GetUpcomingEventsSuccessAction {
    type: typeof GET_UPCOMING_EVENTS_SUCCESS;
    payload: Array<EventSingle>;
}

export interface GetUpcomingEventsErrorAction {
    type: typeof GET_UPCOMING_EVENTS_ERROR;
    payload: UpcomingEventsError;
}

export type UpcomingEventsAction =
    | GetUpcomingEventsAction
    | GetUpcomingEventsSuccessAction
    | GetUpcomingEventsErrorAction;
