import {
    ConfirmEmailOptionsAction,
    ConfirmEmailState,
    CONFIRM_EMAIL,
    CONFIRM_EMAIL_ERROR,
    CONFIRM_EMAIL_SUCCESS,
} from '../_types/confirmEmail';

const initialState: ConfirmEmailState = {
    data: null,
    loading: false,
    error: null,
};

export default (state = initialState, action: ConfirmEmailOptionsAction): ConfirmEmailState => {
    switch (action.type) {
        case CONFIRM_EMAIL_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case CONFIRM_EMAIL:
            return {
                ...state,
                loading: true,
            };
        case CONFIRM_EMAIL_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
