import { DialogAction, DialogState, TOGGLE_LOGOUT_DIALOG, TOGGLE_REGISTRATION_CONFIRM_DIALOG } from '../_types/dialog';

const initialState: DialogState = {
    openLogOutDialog: false,
    openRegistraionConfirDialog: false,
};

export default (state = initialState, action: DialogAction): DialogState => {
    switch (action.type) {
        case TOGGLE_LOGOUT_DIALOG:
            return {
                ...state,
                openLogOutDialog: action.payload,
            };
        case TOGGLE_REGISTRATION_CONFIRM_DIALOG:
            return {
                ...state,
                openRegistraionConfirDialog: action.payload,
            };
        default:
            return state;
    }
};
