export const GET_PASS_OFFERS = 'GET_PASS_OFFERS';
export const GET_PASS_OFFERS_SUCCESS = 'GET_PASS_OFFERS_SUCCESS';
export const GET_PASS_OFFERS_ERROR = 'GET_PASS_OFFERS_ERROR';

export const ACCEPT_PASS_OFFERS = 'ACCEPT_PASS_OFFERS';
export const ACCEPT_PASS_OFFERS_SUCCESS = 'ACCEPT_PASS_OFFERS_SUCCESS';
export const ACCEPT_PASS_OFFERS_ERROR = 'ACCEPT_PASS_OFFERS_ERROR';

export interface PassOffersRequest {
    userId: string;
    salesChannelId: number;
}

export interface AcceptPassOffersRequest {
    userId: string;
    offerIds: number[];
    eventId: number;
    salesChannelId: number;
}

export interface PassOffersSingle {
    id: number;
    eventId?: string;
    title?: string;
    venue?: string;
    time?: string;
    timezone: string;
    securityPhrase?: string;
    league?: string;
    avfmsSport?: string;
    logoUrl?: string;
    awayTeamLogoUrl?: string;
    status?: number;
    salesChannelId?: number;
    offers: OfferSingle[];
    isOffersAccepted?: boolean;
}

export interface OfferSingle {
    id: number;
}

export interface PassOffersError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface PassOffersState {
    data: Array<PassOffersSingle> | null;
    loading: boolean;
    error: PassOffersError | null;
}

export interface GetPassOffersAction {
    type: typeof GET_PASS_OFFERS;
}

export interface GetPassOffersSuccessAction {
    type: typeof GET_PASS_OFFERS_SUCCESS;
    payload: Array<PassOffersSingle>;
}

export interface GetPassOffersErrorAction {
    type: typeof GET_PASS_OFFERS_ERROR;
    payload: PassOffersError;
}

export interface AcceptPassOffersAction {
    type: typeof ACCEPT_PASS_OFFERS;
}

export interface AcceptPassOffersSuccessAction {
    type: typeof ACCEPT_PASS_OFFERS_SUCCESS;
    payload: { isOffersAccepted: boolean; eventId: number };
}

export interface AcceptPassOffersErrorAction {
    type: typeof ACCEPT_PASS_OFFERS_ERROR;
    payload: PassOffersError;
}

export type PassOffersAction =
    | GetPassOffersAction
    | GetPassOffersSuccessAction
    | GetPassOffersErrorAction
    | AcceptPassOffersAction
    | AcceptPassOffersSuccessAction
    | AcceptPassOffersErrorAction;
