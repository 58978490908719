import { FcOk, FcCancel } from 'react-icons/fc';
interface TextProps {
    message: string;
    type?: 'success' | 'error' | 'primary';
    position?: 'left' | 'center' | 'right';
}

const Text: React.FC<TextProps> = ({ message, type, position = 'center' }) => {
    return (
        <>
            {type === 'success' && (
                <h6 className={`text-success text-${position}`}>
                    <FcOk /> {message}
                </h6>
            )}
            {type === 'error' && (
                <h6 className={`text-danger text-${position}`}>
                    <FcCancel /> {message}
                </h6>
            )}
            {type === 'primary' && (
                <h6 className={`text-primary text-${position}`}>
                    <FcCancel /> {message}
                </h6>
            )}
        </>
    );
};
export default Text;
