import { loginSelector } from '../_selectors';
import { useSelector } from 'react-redux';

export const useIsLoggedIn = (): boolean => {
    const { authorized } = useSelector(loginSelector);

    const isUserLoggedIn =
        authorized || (!!sessionStorage.getItem('loggedIn') && !!sessionStorage.getItem('signupToken'));

    return isUserLoggedIn;
};
