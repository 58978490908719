import {
    UpdateContactInfoOptionsAction,
    UpdateContactInfoState,
    UPDATE_CONTACT_INFO,
    UPDATE_CONTACT_INFO_ERROR,
    UPDATE_CONTACT_INFO_INITIAL,
    UPDATE_CONTACT_INFO_SUCCESS,
} from '../_types/updateContactInfo';

const initialState: UpdateContactInfoState = {
    data: null,
    loading: false,
    error: null,
};

export default (state = initialState, action: UpdateContactInfoOptionsAction): UpdateContactInfoState => {
    switch (action.type) {
        case UPDATE_CONTACT_INFO_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case UPDATE_CONTACT_INFO:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_CONTACT_INFO_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case UPDATE_CONTACT_INFO_INITIAL:
            return initialState;
        default:
            return state;
    }
};
