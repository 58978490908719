import {
    GET_INVENTORY,
    GET_INVENTORY_ERROR,
    GET_INVENTORY_SUCCESS,
    InventoryAction,
    InventoryState,
} from '../_types/inventory';

const initialState: InventoryState = {
    data: null,
    loading: false,
    error: null,
};

export default (state = initialState, action: InventoryAction): InventoryState => {
    switch (action.type) {
        case GET_INVENTORY_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case GET_INVENTORY:
            return {
                ...state,
                loading: true,
            };
        case GET_INVENTORY_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
