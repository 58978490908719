export const GET_SALES_CHANNEL = 'GET_SALES_CHANNEL';
export const GET_SALES_CHANNEL_SUCCESS = 'GET_SALES_CHANNEL_SUCCESS';
export const GET_SALES_CHANNEL_ERROR = 'GET_SALES_CHANNEL_ERROR';

export interface SalesChannelRequest {
    salesChannelUrl: string;
}

export type TextBuyUI =
    | {
          finalMessage?: string;
          finalMessageOffset?: number;
          id?: number;
          initialMessage?: string;
          initialMessageOffset?: number;
          isFinalMessageEnabled?: boolean;
          isReminderMessageEnabled?: boolean;
          isSpecialMessageEnabled?: boolean;
          isThirdPartyLogin?: boolean;
          logoUrl?: string;
          reminderMessage?: string;
          reminderMessageFrequency?: number;
          salesChannelId: number;
          signupBackgroundUrl?: string;
          signupCustomText?: string;
          specialMessage?: string;
          specialMessageOffset?: number;
          ticketDetailCustomText?: string;
          tmVideoUrl?: string;
          tmVideoImage?: string;
          tmLogoUrl?: string;
          tmLoginBg?: string;
      }
    | undefined;

export type SalesChannelUI =
    | {
          name?: string;
          clientId?: number;
          provider?: string;
          logoUrl?: string;
          imageUrls?: string;
          isDemo?: boolean;
          isTextBuy?: boolean;
          isPass?: boolean;
          privacyPolicy?: string;
          termsOfUse?: string;
          isTicketmaster?: boolean;
          isOkta?: boolean;
      }
    | undefined;

type TextBuySSO = {
    salesChannelId: number;
    loginBg: string;
    logoUrl: string;
    videoUrl: string;
    videoImage: string;
    sms1: string;
    sms2: string;
    sms3: string;
};

export interface SalesChannel {
    id: number;
    color1: string;
    color2: string;
    color3: string;
    dateFormat: string;
    notificationsPageText: string;
    appStoreUrl: string;
    googlePlayUrl: string;
    signupPageBackgroundUrl: string;
    signupPageMobileUrl: string;
    mapImageUrl: string;
    currencyType: string;
    supportEmail: string;
    supportPhone: string;
    customUrl: string;
    defaultVenue: unknown;
    defaultTimezone: unknown;
    defaultSecurityPhrase: unknown;
    defaultCategory: unknown;
    defaultLeague: unknown;
    defaultAvfmsSport: unknown;
    defaultRewards: unknown;
    defaultPriceRules: unknown;
    defaultBackgroundImageUrl: unknown;
    defaultSeatUpgradeBackgroundImageUrl: string;
    barcodeRegex: string;
    phoneMask: string;
    additionalTicketMessage: string;
    defaultAvailableStartOffset: string;
    defaultAvailableEndOffset: string;
    defaultLogoUrl: unknown;
    selectSeatsText: string;
    notificationsPageFinePrint: string;
    barcodeInputPlaceholder: string;
    loginInputPlaceholder: string;
    eventTitleDelimiter: string;
    avfmsRegex: string;
    loginLogoUrl: string;
    howItWorksImageUrl: string;
    allowsMapListToggle: boolean;
    defaultsToMapView: boolean;
    showSeatsBeforeCheckout: boolean;
    requireName: boolean;
    integrated: boolean;
    showsBarcodeOnTicket: boolean;
    showLoginInputs: boolean;
    showsOriginalPrice: boolean;
    showsBarcodeInput: true;
    skipToBarcodeModal: boolean;
    showQuantityRemaining: boolean;
    bypassIpaInventory: boolean;
    faq: string;
    faqDefault: string;
    faqPass: string;
    mapMarkers: string;
    textMessage: string;
    barcodeEntryText: string;
    maxQuantityRemaining: number;
    reserveTimeout: number;
    salesChannelId: number;
    afmsRegex: string;
    isTextBuy: boolean;
    isTicketmaster: boolean;
    isOkta: boolean;
    SalesChannel: SalesChannelUI;
    TextBuyUi: TextBuyUI;
    TextBuySSO?: TextBuySSO;
    emailList: Array<string>;
}

export interface SalesChannelError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface SalesChannelState {
    data: SalesChannel | null;
    loading: boolean;
    error: SalesChannelError | null;
}

export interface GetSalesChannelAction {
    type: typeof GET_SALES_CHANNEL;
}

export interface GetSalesChannelSuccessAction {
    type: typeof GET_SALES_CHANNEL_SUCCESS;
    payload: SalesChannel;
}

export interface GetSalesChannelErrorAction {
    type: typeof GET_SALES_CHANNEL_ERROR;
    payload: SalesChannelError;
}

export type SalesChannelAction = GetSalesChannelAction | GetSalesChannelSuccessAction | GetSalesChannelErrorAction;
