export const BASE_PATH = '/pass';
export const LOGIN = '/pass/login';
export const REGISTER = '/pass/register';
export const FORGOT_PASSWORD = '/pass/forgotpassword';
export const SET_NEW_PASSWORD = '/pass/setnewpassword/:token';
export const CONFIRM_EMAIL = '/pass/confirmemail';
export const FINALIZE_SIGNUP = '/pass/createaccount/:signupToken/addpayment';
export const TTB_FINALIZE_SIGNUP = '/pass/createaccount/:signupToken/ttbaddpayment';
export const CREATE_ACCOUNT = '/pass/createaccount/:signupToken';
export const TELL_YOUR_FRIENDS = '/pass/socialmediashare';
export const BRAND_AMBASSADOR = '/pass/brandambaddasor';
// Protected routes
export const LOGOUT = '/pass/myaccount/logout';
export const UPDATE_PAYMENT_CARD = '/pass/myaccount/payment';
export const TTB_UPDATE_PAYMENT_CARD = '/pass/myaccount/ttbupdatepayment';
export const ADD_GUEST_TICKET = '/pass/myaccount/addguestticket';
export const PURCHASE_HISTORY = '/pass/myaccount/purchases';
export const SIT_WITH_FRIENDS = '/pass/myaccount/sitwithfriends';
export const SETTINGS = '/pass/myaccount/settings';
export const ACCEPT_PASS = '/pass/myaccount/acceptpass';
export const CONTACT_US = '/pass/myaccount/contactus';
export const SHARE_INVITE = '/pass/myaccount/sharewithfriends';
export const MY_ACCOUNT = '/pass/myaccount';
export const SEAT_VIEW = '/pass/seatview/:venue/:seat';
