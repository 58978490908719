import { ThunkAction } from 'redux-thunk';
import { configUrl } from '../config';
import { setSalesChannelError, setSalesChannelSuccess } from '../_actions/salesChannel.actions';
import { RootState } from '../_reducers';
import { SalesChannel, SalesChannelAction, SalesChannelError, SalesChannelRequest } from '../_types/salesChannel';

export const getSalesChannelByUrl = (
    data: SalesChannelRequest,
): ThunkAction<void, RootState, null, SalesChannelAction> => {
    return async (dispatch) => {
        const url = `${configUrl}/api/getSalesChannelByUrl/${data.salesChannelUrl}`;

        try {
            const res = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!res.ok) {
                const resData: SalesChannelError = await res.json();
                dispatch(setSalesChannelError(resData));
                return;
            }

            const resData: SalesChannel = await res.json();
            dispatch(setSalesChannelSuccess(resData));
        } catch (err) {
            dispatch(setSalesChannelError({ message: 'Error occurred!' }));
            throw err;
        }
    };
};
