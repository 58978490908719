import {
    DELETE_PAYMENT_CARD,
    DELETE_PAYMENT_CARD_ERROR,
    DELETE_PAYMENT_CARD_SUCCESS,
    DELETE_PAYMENT_CARD_INITIAL,
    DeletePaymentCardActions,
    DeletePaymentCardState,
} from '../_types/deletePaymentCard';

const initialState: DeletePaymentCardState = {
    data: null,
    loading: false,
    error: null,
};

export default (state = initialState, action: DeletePaymentCardActions): DeletePaymentCardState => {
    switch (action.type) {
        case DELETE_PAYMENT_CARD_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case DELETE_PAYMENT_CARD:
            return {
                ...state,
                loading: true,
            };
        case DELETE_PAYMENT_CARD_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case DELETE_PAYMENT_CARD_INITIAL:
            return initialState;
        default:
            return state;
    }
};
