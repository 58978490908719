export const GET_PASS_SUBSCRIPTION = 'GET_PASS_SUBSCRIPTION';
export const GET_PASS_SUBSCRIPTION_SUCCESS = 'GET_PASS_SUBSCRIPTION_SUCCESS';
export const GET_PASS_SUBSCRIPTION_ERROR = 'GET_PASS_SUBSCRIPTION_ERROR';

export const ADD_PASS_SUBSCRIPTION = 'ADD_PASS_SUBSCRIPTION';
export const ADD_PASS_SUBSCRIPTION_SUCCESS = 'ADD_PASS_SUBSCRIPTION_SUCCESS';
export const ADD_PASS_SUBSCRIPTION_ERROR = 'ADD_PASS_SUBSCRIPTION_ERROR';

export const CANCEL_PASS_SUBSCRIPTION = 'CANCEL_PASS_SUBSCRIPTION';
export const CANCEL_PASS_SUBSCRIPTION_SUCCESS = 'CANCEL_PASS_SUBSCRIPTION_SUCCESS';
export const CANCEL_PASS_SUBSCRIPTION_ERROR = 'CANCEL_PASS_SUBSCRIPTION_ERROR';

export interface PassSubscriptionRequest {
    userId: string;
    salesChannelId: number;
}

export interface passIdWithQuantiry {
    passId: number;
    quantity: number;
}

export interface AddPassSubscriptionRequest {
    userId: string;
    passesToSubscribe: passIdWithQuantiry[];
    salesChannelId: number;
}

export interface CancelPassSubscriptionRequest {
    passId: number;
    userId: string;
    salesChannelId: number;
}

export interface PassSubscriptionSingle {
    id: number;
    userId: number;
    passId: number;
    subscriptionId: string;
    title: string;
    description?: string;
    quantity: number;
    subscribed: boolean;
    createdAt: string;
    updatedAt: string;
    created: unknown;
    updated: unknown;
    daysLeft: number;
    renewalDate: string;
    startDate: string;
    endDate: string;
    amount: number;
}

export interface CancelPassSubscriptionSuccessResponse {
    message: string;
}

export interface CancelPassSubscriptionSuccess {
    passId: number;
    isCancelled: boolean;
}

export interface PassSubscriptionError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface PassSubscriptionState {
    data: Array<PassSubscriptionSingle> | null;
    loading: boolean;
    error: PassSubscriptionError | null;
    isCancelLoading: boolean;
    cancelError: PassSubscriptionError | null;
}

export interface GetPassSubscriptionAction {
    type: typeof GET_PASS_SUBSCRIPTION;
}

export interface GetPassSubscriptionSuccessAction {
    type: typeof GET_PASS_SUBSCRIPTION_SUCCESS;
    payload: Array<PassSubscriptionSingle>;
}

export interface GetPassSubscriptionErrorAction {
    type: typeof GET_PASS_SUBSCRIPTION_ERROR;
    payload: PassSubscriptionError | null;
}

export interface AddPassSubscriptionAction {
    type: typeof ADD_PASS_SUBSCRIPTION;
}

export interface AddPassSubscriptionSuccessAction {
    type: typeof ADD_PASS_SUBSCRIPTION_SUCCESS;
    payload: Array<PassSubscriptionSingle>;
}

export interface AddPassSubscriptionErrorAction {
    type: typeof ADD_PASS_SUBSCRIPTION_ERROR;
    payload: PassSubscriptionError;
}
export interface CancelPassSubscriptionAction {
    type: typeof CANCEL_PASS_SUBSCRIPTION;
}

export interface CancelPassSubscriptionSuccessAction {
    type: typeof CANCEL_PASS_SUBSCRIPTION_SUCCESS;
    payload: CancelPassSubscriptionSuccess;
}

export interface CancelPassSubscriptionErrorAction {
    type: typeof CANCEL_PASS_SUBSCRIPTION_ERROR;
    payload: PassSubscriptionError;
}

export type PassSubscriptionAction =
    | GetPassSubscriptionAction
    | GetPassSubscriptionSuccessAction
    | GetPassSubscriptionErrorAction
    | AddPassSubscriptionAction
    | AddPassSubscriptionSuccessAction
    | AddPassSubscriptionErrorAction
    | CancelPassSubscriptionAction
    | CancelPassSubscriptionSuccessAction
    | CancelPassSubscriptionErrorAction;
