import { useEffect, useState } from 'react';

import { smsCustomTextType } from '../../config';
import Loader from '../../_components/Loader/Loader';
import ButtonSpinner from '../../_components/ButtonSpinner';
import { usePreloadImages } from '../../_hooks/usePreloadImages';
import { ChatBubble } from '../../_components/TMLogin/ChatBubble';
import * as ROUTES from '../../_constants/routes';
import { LegalDisclaimer } from '../../_components/TMLogin/LegalDisclaimer';

// Assets
import axsLogoSvg from '../../_assets/axsLogoSvg.svg';

import { FaqModal } from '../../_components/TMLogin/FaqModal';
import NoErrorLoader from '../../_components/NoErrorLoader/NoErrorLoader';
import { useSalesChannel } from '../../_hooks/useSalesChannel';
import { useHistory } from 'react-router-dom';
import { PageHeaderBanner } from '../../_components/PageHeaderBanner';
import { VideoModal } from '../../_components/VideoModal';

const LandingPage: React.FC = () => {
    // imagesLoaded = false until all TTB UI images are loaded
    const { preloadImages, imagesLoaded } = usePreloadImages();

    const [showFaqModal, setShowFaqModal] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(false);
    const source = sessionStorage.getItem('source');

    const history = useHistory();
    const {
        textBuyUI,
        salesChannelId,
        textBuySSO,
        salesChannelName,
        sourceSpecificLogo,
        sourceSpecificBackground,
        sourceSpecificLogoBackground,
        sourceSpecificThumbnail,
        sourceSpecificSliderImages,
        salesChannelConfig,
    } = useSalesChannel();
    const isAXSOrVeritixProvider = salesChannelConfig?.provider === 'axs' || salesChannelConfig?.provider === 'veritix';
    const customChatBubbleText =
        salesChannelConfig &&
        salesChannelConfig.smsTextOverrides &&
        (source ? (salesChannelConfig.smsTextOverrides[source] as smsCustomTextType) : null);

    useEffect(() => {
        if (!textBuyUI || !textBuySSO) {
            return;
        }
        /**
         * Preload
         */
        preloadImages(
            [
                textBuyUI.signupBackgroundUrl,
                textBuySSO?.logoUrl,
                textBuySSO?.videoImage,
                sourceSpecificLogo,
                sourceSpecificBackground,
                sourceSpecificLogoBackground,
                sourceSpecificThumbnail,
            ]
                .filter(Boolean)
                .map(String),
        );
    }, [textBuyUI, textBuySSO]);

    /**
     * Watch for the ttb profile and only fire when images and profile loads.
     */
    useEffect(() => {
        if (!imagesLoaded) {
            return;
        }
    }, [imagesLoaded]);

    if (!imagesLoaded) {
        return <NoErrorLoader loading={!imagesLoaded} />;
    }
    if (!textBuyUI) {
        return <Loader message="Unable to fetch salesChannel data!" />;
    }

    return (
        <div
            className="w-100 flex min-h-screen fw-container bg-center bg-cover md:py-4"
            style={{
                backgroundImage: `url(${
                    sourceSpecificBackground ? sourceSpecificBackground : textBuyUI?.signupBackgroundUrl
                })`,
            }}
        >
            <div className="container w-full md:my-auto md:max-w-xl px-0 mx-auto md:border-2 border-white md:rounded-xl">
                <PageHeaderBanner hideHomeIcon />
                <div className="py-3 px-10">
                    <div className={'grid mx-auto max-w-sm'}>
                        <ChatBubble id="1">
                            {customChatBubbleText?.sms1 ||
                                textBuySSO?.sms1 ||
                                'Interested in getting access to last-minute tickets and more?'}
                        </ChatBubble>

                        <ChatBubble id="2" isAnswer>
                            {customChatBubbleText?.sms2 || textBuySSO?.sms2 || 'Yes, sure! How does it work?'}
                        </ChatBubble>

                        <ChatBubble id="3">
                            {customChatBubbleText?.sms3 ||
                                textBuySSO?.sms3 ||
                                'Watch this video, it’s as easy as sending a text message!'}
                            <div className={'w-full mt-1 cursor-pointer'} onClick={() => setShowVideoModal(true)}>
                                <img
                                    src={sourceSpecificThumbnail ? sourceSpecificThumbnail : textBuySSO?.videoImage}
                                    className={'rounded-lg w-full'}
                                    alt={'Pogoseat Video'}
                                />
                            </div>
                        </ChatBubble>
                    </div>
                </div>
                <div
                    className="py-3 px-4"
                    style={{
                        background: 'rgb(22, 22, 22, .6)',
                    }}
                >
                    <div className="text-center m-2">
                        <div className={'flex justify-content-center w-full'}>
                            <div className={'inline-block self-center mr-3 tm-login-faq-btn'}>
                                <span
                                    className={'inline-block bg-white cursor-pointer bg-blue-600 rounded-full'}
                                    onClick={() => setShowFaqModal(true)}
                                    style={{ width: '18px', height: '18px' }}
                                >
                                    <svg
                                        style={{ marginBottom: '8px' }}
                                        width="4"
                                        height="12"
                                        viewBox="0 0 4 12"
                                        fill="blue"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.875 1.875C0.875 1.57663 0.993526 1.29048 1.2045 1.07951C1.41548 0.868527 1.70163 0.75 2 0.75C2.29837 0.75 2.58452 0.868527 2.79549 1.07951C3.00647 1.29048 3.125 1.57663 3.125 1.875C3.125 2.17337 3.00647 2.45952 2.79549 2.67049C2.58452 2.88147 2.29837 3 2 3C1.70163 3 1.41548 2.88147 1.2045 2.67049C0.993526 2.45952 0.875 2.17337 0.875 1.875ZM1.4375 4.5H2.5625C2.66562 4.5 2.75 4.58437 2.75 4.6875V11.0625C2.75 11.1656 2.66562 11.25 2.5625 11.25H1.4375C1.33437 11.25 1.25 11.1656 1.25 11.0625V4.6875C1.25 4.58437 1.33437 4.5 1.4375 4.5Z"
                                            fill="#026BDE"
                                        />
                                    </svg>
                                </span>
                            </div>

                            <button
                                className="tm-login-btn text-sm md:text-base font-medium leading-6 rounded border-gray-200 py-2 hover:text-white focus:outline-none"
                                style={{ maxWidth: '100%', width: '300px' }}
                                onClick={() => {
                                    history.push(ROUTES.REGISTER);
                                }}
                            >
                                <span
                                    style={{
                                        padding: '3px',
                                        display: 'inline-block',
                                    }}
                                >
                                    <ButtonSpinner show={false} buttonLabel={'Continue with '} />
                                </span>
                                {isAXSOrVeritixProvider ? (
                                    <img
                                        src={axsLogoSvg}
                                        alt={'AXS'}
                                        style={{ height: '14px' }}
                                        className={`h-4 inline-block mb-1 ml-1`}
                                    />
                                ) : (
                                    'Login/Register'
                                )}
                            </button>
                        </div>
                        <p className={'text-white mt-3 text-sm'}>
                            By signing up you&apos;ll receive text offers straight to your mobile.
                        </p>
                    </div>
                </div>
                {!isAXSOrVeritixProvider && (
                    <LegalDisclaimer
                        salesChannelId={`${salesChannelId}`}
                        salesChannelName={salesChannelName}
                        tosURL={salesChannelConfig.termsOfUse}
                        privacyPolicyURL={salesChannelConfig.privacyPolicy}
                    />
                )}
            </div>

            <FaqModal isOpen={showFaqModal} onRequestClose={() => setShowFaqModal(false)} />

            <VideoModal
                isOpen={showVideoModal}
                onRequestClose={() => setShowVideoModal(false)}
                videoUrl={textBuySSO?.videoUrl || salesChannelConfig?.promoVideoUrl}
                sliderImages={sourceSpecificSliderImages}
            />
        </div>
    );
};

export default LandingPage;
