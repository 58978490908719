import {
    GET_UPCOMING_EVENTS,
    GET_UPCOMING_EVENTS_ERROR,
    GET_UPCOMING_EVENTS_SUCCESS,
    UpcomingEventsAction,
    UpcomingEventsState,
} from '../_types/event';

const initialState: UpcomingEventsState = {
    data: null,
    loading: false,
    error: null,
};

export default (state = initialState, action: UpcomingEventsAction): UpcomingEventsState => {
    switch (action.type) {
        case GET_UPCOMING_EVENTS_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case GET_UPCOMING_EVENTS:
            return {
                ...state,
                loading: true,
            };
        case GET_UPCOMING_EVENTS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
