import './index.scss';
import {
    FaRegCheckCircle,
    FaCreditCard,
    FaRegSun,
    FaSignOutAlt,
    FaShoppingBasket,
    FaHeadphonesAlt,
    FaShare,
} from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
// import { BsFillPersonPlusFill } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import { FcCalendar } from 'react-icons/fc';
import * as ROUTES from '../../_constants/routes';

import 'react-modern-drawer/dist/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSideNavigation } from '../../_actions/sideNav.actions';
import TeamLogo from '../TeamLogo';
import PageHeader from '../PageHeader';
import { setLogOutDialogConfirmation } from '../../_actions/dialog.actions';
import moment from 'moment';
import { sideNavSelector, userAccountSelector } from '../../_selectors';
import { useIsTextBuy } from '../../_hooks/useIsTextBuy';
import pogoSeatLogo from '../../_assets/pogoseat_white_logo.png';
import { useSalesChannel } from '../../_hooks/useSalesChannel';

const SideNav: React.FC = () => {
    const { open } = useSelector(sideNavSelector);
    const { data } = useSelector(userAccountSelector);
    const dispatch = useDispatch();

    const { salesChannelId } = useSalesChannel();
    const isTextBuy = useIsTextBuy();

    const onClickLogOut = () => {
        dispatch(toggleSideNavigation(!open));
        dispatch(setLogOutDialogConfirmation(true));
    };

    return (
        <header className="header w-100 pt-4">
            <div className="d-flex flex-column">
                <div className="profile px-5">
                    <div className="text-light">
                        <div className="header--close-icon text-white cursor-pointer">
                            <AiOutlineClose onClick={() => dispatch(toggleSideNavigation(!open))} />
                        </div>
                        <TeamLogo positionOnMobile="left" />
                        <PageHeader title={`${data?.firstName} ${data?.lastName}`} />
                    </div>
                    <div className="text-light py-4">
                        <FcCalendar className="profile-calender--icon mt-2 mr-3" />
                        <div className="ml-4">
                            <span>Date Joined :</span>
                            <p>{moment(data?.createdAt).format('MM-DD-YYYY')}</p>
                        </div>
                    </div>
                </div>
                <nav className="nav-menu">
                    <ul>
                        {!isTextBuy && (
                            <NavLink
                                exact
                                className="pt-3 pb-3 px-5"
                                to={ROUTES.ACCEPT_PASS}
                                activeClassName={'nav-active-menu'}
                                onClick={() => dispatch(toggleSideNavigation(!open))}
                            >
                                <FaRegCheckCircle />
                                <span className="pl-4">Accept your pass</span>
                            </NavLink>
                        )}
                        {/* <NavLink
                            exact
                            className="pt-3 pb-3 px-5"
                            to={ROUTES.ADD_GUEST_TICKET}
                            activeClassName={'nav-active-menu'}
                            onClick={() => dispatch(toggleSideNavigation(!open))}
                        >
                            <BsFillPersonPlusFill />
                            <span className="pl-4">Add Guest Tickets</span>
                        </NavLink>
                        <NavLink
                            exact
                            className="pt-3 pb-3 px-5"
                            to={ROUTES.SIT_WITH_FRIENDS}
                            activeClassName={'nav-active-menu'}
                            onClick={() => dispatch(toggleSideNavigation(!open))}
                        >
                            <BsFillPersonPlusFill />
                            <span className="pl-4">Sit with Friends</span>
                        </NavLink> */}
                        <NavLink
                            exact
                            className="pt-3 pb-3 px-5"
                            to={isTextBuy ? ROUTES.TTB_UPDATE_PAYMENT_CARD : ROUTES.UPDATE_PAYMENT_CARD}
                            activeClassName={'nav-active-menu'}
                            onClick={() => dispatch(toggleSideNavigation(!open))}
                        >
                            <FaCreditCard />
                            <span className="pl-4">Payment</span>
                        </NavLink>
                        <NavLink
                            exact
                            className="pt-3 pb-3 px-5"
                            to={ROUTES.PURCHASE_HISTORY}
                            activeClassName={'nav-active-menu'}
                            onClick={() => dispatch(toggleSideNavigation(!open))}
                        >
                            <FaShoppingBasket />
                            <span className="pl-4">Your Purchases</span>
                        </NavLink>
                        <NavLink
                            exact
                            className="pt-3 pb-3 px-5"
                            to={ROUTES.SETTINGS}
                            activeClassName={'nav-active-menu'}
                            onClick={() => dispatch(toggleSideNavigation(!open))}
                        >
                            <FaRegSun />
                            <span className="pl-4">Settings</span>
                        </NavLink>
                        <NavLink
                            exact
                            className="pt-3 pb-3 px-5"
                            to={ROUTES.CONTACT_US}
                            activeClassName={'nav-active-menu'}
                            onClick={() => dispatch(toggleSideNavigation(!open))}
                        >
                            <FaHeadphonesAlt />
                            <span className="pl-4">Support</span>
                        </NavLink>
                        <NavLink
                            exact
                            className="pt-3 pb-3 px-5 px-5"
                            to={ROUTES.SHARE_INVITE}
                            activeClassName={'nav-active-menu'}
                            onClick={() => dispatch(toggleSideNavigation(!open))}
                        >
                            <FaShare />
                            <span className="pl-4">Tell your friends</span>
                        </NavLink>
                        {salesChannelId === 128 && (
                            <li className="pt-3 pb-3 px-5">
                                <a href="https://bp.mlb.com/TEX_Upgrade" target="blank">
                                    <img className="pogoseat--icon" src={pogoSeatLogo} alt="Pogoseat" />
                                    <span className="pl-4">Shop Seat Upgrades</span>
                                </a>
                            </li>
                        )}
                        <a className="pt-3 pb-3 px-5" href="#" onClick={onClickLogOut}>
                            <FaSignOutAlt />
                            <span className="pl-4">Logout</span>
                        </a>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default SideNav;
