export const TOGGLE_SIDENAV = 'TOGGLE_SIDENAV';

export interface SideNavState {
    open: boolean;
}

export interface ToggleSideNavAction {
    type: typeof TOGGLE_SIDENAV;
    payload: boolean;
}

export type SideNavAction = ToggleSideNavAction;
