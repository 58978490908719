import { HiOutlineHome } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../../_constants/routes';
import { useIsOkta } from '../../_hooks/useIsOkta';
import { useIsTicketmaster } from '../../_hooks/useIsTicketmaster';
import { useSalesChannel } from '../../_hooks/useSalesChannel';
import { useSelector } from 'react-redux';
import { userAccountSelector } from '../../_selectors';
import { useIsSeatGeek } from '../../_hooks/useIsSeatGeek';

type Props = {
    hideHomeIcon?: boolean;
};

export const PageHeaderBanner: React.FC<Props> = ({ hideHomeIcon = false }) => {
    const { data } = useSelector(userAccountSelector);
    if (data && data.source) {
        sessionStorage.setItem('source', data.source);
    }

    const { textBuyUI, textBuySSO, sourceSpecificLogoBackground, sourceSpecificLogo } = useSalesChannel();
    const isTicketMaster = useIsTicketmaster();
    const isOkta = useIsOkta();
    const isSeatGeek = useIsSeatGeek();

    const isNonSSO = !isTicketMaster && !isOkta && !isSeatGeek;

    const logoUrl = isNonSSO ? textBuySSO?.logoUrl : textBuyUI?.tmLogoUrl;
    const logoBackground = isNonSSO ? textBuySSO?.loginBg : textBuyUI?.tmLoginBg;
    const history = useHistory();
    return (
        <>
            {!hideHomeIcon && (
                <HiOutlineHome
                    className="absolute m-2 cursor-pointer text-white"
                    onClick={() => history.push(ROUTES.BASE_PATH)}
                />
            )}
            <div
                className="py-5 px-10 md:rounded-t-xl bg-blend-overlay"
                style={{
                    background: sourceSpecificLogoBackground
                        ? sourceSpecificLogoBackground
                        : logoBackground || textBuyUI?.tmLoginBg,
                }}
            >
                <div className="mw-100">
                    <div className="md:w-96 mx-auto blur text-center">
                        <img
                            src={sourceSpecificLogo ? sourceSpecificLogo : logoUrl || textBuyUI?.tmLogoUrl}
                            style={{ maxWidth: '100%' }}
                            className={'tm-team-logo mx-auto'}
                            alt={''}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
