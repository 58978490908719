import { lazy } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import * as ROUTES from './_constants/routes';
import ProtectedRoute from './_components/ProtectedRoute';
import LandingPage from './pages/LandingPage';

const LoginPage = lazy(() => import('./pages/LoginPage/index'));
const SignUpPage = lazy(() => import('./pages/SignUpPage/index'));
const CreateAccountPage = lazy(() => import('./pages/CreateAccountPage/index'));
const ForgotPasswordPage = lazy(() => import('./pages/ForgotPasswordPage/index'));
const SetNewPasswordPage = lazy(() => import('./pages/SetNewPasswordPage/index'));
const ConfirmEmailPage = lazy(() => import('./pages/ConfirmEmailPage/index'));
const SocialMediaShare = lazy(() => import('./pages/SocialMediaShare/index'));
const BrandAmbassadorPage = lazy(() => import('./pages/BrandAmbassadorPage/index'));
const TMLoginPage = lazy(() => import('./pages/TMLoginPage/index'));
const OktaLoginPage = lazy(() => import('./pages/OktaLoginPage/index'));
const SGLoginPage = lazy(() => import('./pages/SGLoginPage/index'));
const finalizeSignUpAndSubscribePass = lazy(() => import('./pages/finalizeSignUpAndSubscribePass'));
const ttbFinalizeSignUp = lazy(() => import('./pages/ttbFinalizeSignUp'));
const AcceptPassPage = lazy(() => import('./pages/MyAccountPage/Pages/AcceptPass'));
const MyAccountPage = lazy(() => import('./pages/MyAccountPage'));
const TtbUpdatePaymentPage = lazy(() => import('./pages/MyAccountPage/Pages/TtbUpdatePayment'));
const SettingsPage = lazy(() => import('./pages/MyAccountPage/Pages/SettingsPage'));
const PaymentPage = lazy(() => import('./pages/MyAccountPage/Pages/PaymentPage'));
const LogOutPage = lazy(() => import('./pages/MyAccountPage/Pages/LogOutPage'));
const PurchaseHistoryPage = lazy(() => import('./pages/MyAccountPage/Pages/PurchaseHistoryPage'));
// const AddGuestTickets = lazy(() => import('./pages/MyAccountPage/Pages/AddGuestTickets'));
// const SitWithFriends = lazy(() => import('./pages/MyAccountPage/Pages/SitWithFriends'));
const ContactUsPage = lazy(() => import('./pages/MyAccountPage/Pages/ContactUs'));
const ShareWithFriends = lazy(() => import('./pages/MyAccountPage/Pages/ShareWithFriends'));
const SeatViewPage = lazy(() => import('./pages/SeatView/index'));

type RoutesProps = {
    loggedIn: boolean;
    isTicketmaster: boolean;
    isOkta: boolean;
    isSeatGeek: boolean;
    isTextBuy: boolean;
    isRegisteredUser: boolean;
};

export default function Routes({
    loggedIn,
    isTicketmaster,
    isOkta,
    isSeatGeek,
    isTextBuy,
    isRegisteredUser,
}: RoutesProps): React.ReactElement {
    const isSSO = isTicketmaster || isOkta || isSeatGeek;

    return (
        <Router>
            <Switch>
                {isSSO && <Route exact path={ROUTES.BASE_PATH} render={() => <Redirect to={ROUTES.LOGIN} />} />}

                {isTicketmaster && <Route exact path={ROUTES.LOGIN} component={TMLoginPage} />}
                {isOkta && <Route exact path={ROUTES.LOGIN} component={OktaLoginPage} />}
                {isSeatGeek && <Route exact path={ROUTES.LOGIN} component={SGLoginPage} />}

                <ProtectedRoute
                    path={ROUTES.FINALIZE_SIGNUP}
                    isAuthenticated={true}
                    authenticationPath={ROUTES.LOGIN}
                    component={finalizeSignUpAndSubscribePass}
                />

                {isTextBuy && [
                    <Route
                        exact
                        key={ROUTES.TELL_YOUR_FRIENDS}
                        path={ROUTES.TELL_YOUR_FRIENDS}
                        component={SocialMediaShare}
                    />,
                    <Route
                        exact
                        key={ROUTES.BRAND_AMBASSADOR}
                        path={ROUTES.BRAND_AMBASSADOR}
                        component={BrandAmbassadorPage}
                    />,
                    <ProtectedRoute
                        key={ROUTES.TTB_FINALIZE_SIGNUP}
                        path={ROUTES.TTB_FINALIZE_SIGNUP}
                        isAuthenticated={true}
                        authenticationPath={ROUTES.LOGIN}
                        component={ttbFinalizeSignUp}
                    />,
                    <ProtectedRoute
                        key={ROUTES.TTB_UPDATE_PAYMENT_CARD}
                        path={ROUTES.TTB_UPDATE_PAYMENT_CARD}
                        isAuthenticated={loggedIn}
                        authenticationPath={ROUTES.LOGIN}
                        component={TtbUpdatePaymentPage}
                    />,
                ]}

                {!isSSO && [
                    <Route exact key={ROUTES.BASE_PATH} path={ROUTES.BASE_PATH} component={LandingPage} />,
                    <Route exact key={ROUTES.LOGIN} path={ROUTES.LOGIN} component={LoginPage} />,
                    <Route exact key={ROUTES.REGISTER} path={ROUTES.REGISTER} component={SignUpPage} />,
                    <Route key={ROUTES.CONFIRM_EMAIL} path={ROUTES.CONFIRM_EMAIL} component={ConfirmEmailPage} />,
                    <Route
                        exact
                        key={ROUTES.FORGOT_PASSWORD}
                        path={ROUTES.FORGOT_PASSWORD}
                        component={ForgotPasswordPage}
                    />,
                    <ProtectedRoute
                        key={ROUTES.SET_NEW_PASSWORD}
                        path={ROUTES.SET_NEW_PASSWORD}
                        isAuthenticated={true}
                        authenticationPath={ROUTES.LOGIN}
                        component={SetNewPasswordPage}
                    />,
                    <ProtectedRoute
                        key={ROUTES.SEAT_VIEW}
                        path={ROUTES.SEAT_VIEW}
                        isAuthenticated={true}
                        authenticationPath={ROUTES.LOGIN}
                        component={SeatViewPage}
                    />,
                ]}

                <ProtectedRoute
                    key={ROUTES.CREATE_ACCOUNT}
                    path={ROUTES.CREATE_ACCOUNT}
                    isAuthenticated={!isRegisteredUser}
                    authenticationPath={ROUTES.LOGIN}
                    component={CreateAccountPage}
                />
                <ProtectedRoute
                    path={ROUTES.LOGOUT}
                    isAuthenticated={true}
                    authenticationPath={ROUTES.LOGIN}
                    component={LogOutPage}
                />

                <ProtectedRoute
                    path={ROUTES.UPDATE_PAYMENT_CARD}
                    isAuthenticated={loggedIn}
                    authenticationPath={ROUTES.LOGIN}
                    component={PaymentPage}
                />

                <ProtectedRoute
                    path={ROUTES.PURCHASE_HISTORY}
                    isAuthenticated={loggedIn}
                    authenticationPath={ROUTES.LOGIN}
                    component={PurchaseHistoryPage}
                />

                <ProtectedRoute
                    path={ROUTES.SETTINGS}
                    isAuthenticated={loggedIn}
                    authenticationPath={ROUTES.LOGIN}
                    component={SettingsPage}
                />

                <ProtectedRoute
                    path={ROUTES.SHARE_INVITE}
                    isAuthenticated={loggedIn}
                    authenticationPath={ROUTES.LOGIN}
                    component={ShareWithFriends}
                />

                {!isTextBuy && (
                    <ProtectedRoute
                        path={ROUTES.ACCEPT_PASS}
                        isAuthenticated={loggedIn}
                        authenticationPath={ROUTES.LOGIN}
                        component={AcceptPassPage}
                    />
                )}

                <ProtectedRoute
                    path={ROUTES.CONTACT_US}
                    isAuthenticated={loggedIn}
                    authenticationPath={ROUTES.LOGIN}
                    component={ContactUsPage}
                />
                {/* <ProtectedRoute
                path={ROUTES.ADD_GUEST_TICKET}
                isAuthenticated={loggedIn}
                authenticationPath={ROUTES.LOGIN}
                component={AddGuestTickets}
            />
            <ProtectedRoute
                path={ROUTES.SIT_WITH_FRIENDS}
                isAuthenticated={loggedIn}
                authenticationPath={ROUTES.LOGIN}
                component={SitWithFriends}
            /> */}

                <ProtectedRoute
                    path={ROUTES.MY_ACCOUNT}
                    isAuthenticated={loggedIn}
                    authenticationPath={ROUTES.LOGIN}
                    component={MyAccountPage}
                />

                <Route render={() => <Redirect to={ROUTES.BASE_PATH} />} />
                {/* Redirect all uncaught routes to login page */}
            </Switch>
        </Router>
    );
}
