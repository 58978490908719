import { useState } from 'react';

const preloadImage = (url: string): Promise<unknown> => {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = resolve;
        img.src = url;
    });
};

export function usePreloadImages(initialLoadingValue = false): {
    preloadImages: (urls: string[]) => void;
    imagesLoaded: boolean;
} {
    const [imagesLoaded, setImagesLoaded] = useState<boolean>(initialLoadingValue);

    const preloadImages = (urls: string[]): void => {
        Promise.all(urls.map((url) => preloadImage(url))).then(() => {
            setImagesLoaded(true);
        });
    };

    return {
        preloadImages,
        imagesLoaded,
    };
}
