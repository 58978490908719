import {
    GET_PAYMENT_DETAILS,
    GET_PAYMENT_DETAILS_ERROR,
    GET_PAYMENT_DETAILS_INITIAL,
    GET_PAYMENT_DETAILS_SUCCESS,
    PaymentDetailsAction,
    PaymentDetailsState,
} from '../_types/paymentDetails';

const initialState: PaymentDetailsState = {
    data: null,
    loading: false,
    error: null,
};

export default (state = initialState, action: PaymentDetailsAction): PaymentDetailsState => {
    switch (action.type) {
        case GET_PAYMENT_DETAILS_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case GET_PAYMENT_DETAILS:
            return {
                ...state,
                loading: true,
            };
        case GET_PAYMENT_DETAILS_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case GET_PAYMENT_DETAILS_INITIAL:
            return initialState;
        default:
            return state;
    }
};
