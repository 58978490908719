import { ReactElement, useMemo } from 'react';

type ChatBubbbleProps = { id: string; isAnswer?: boolean; children: unknown };

export function ChatBubble({ id, children, isAnswer = false }: ChatBubbbleProps): ReactElement {
    const incoming = 'bg-white rounded-r-xl rounded-b-xl';
    const outgoing = 'bg-green-100 rounded-l-xl rounded-t-xl justify-self-end';

    const css = `${isAnswer ? outgoing : incoming} msg${id}`;

    const time = useMemo(() => new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }), []);

    return (
        <div className={`font-medium shadow text-sm md:text-base p-2 mb-4 ${css}`}>
            {children}
            <span className={`mt-2 text-gray-600 text-${isAnswer ? 'right' : 'left'} block text-xs`}>{time}</span>
        </div>
    );
}
