export const DELETE_PAYMENT_CARD = 'DELETE_PAYMENT_CARD';
export const DELETE_PAYMENT_CARD_SUCCESS = 'DELETE_PAYMENT_CARD_SUCCESS';
export const DELETE_PAYMENT_CARD_ERROR = 'DELETE_PAYMENT_CARD_ERROR';
export const DELETE_PAYMENT_CARD_INITIAL = 'DELETE_PAYMENT_CARD_INITIAL';

export interface DeletePaymentCardRequest {
    userId: string;
    salesChannelId: number;
}

export interface DeletePaymentCardError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface DeletePaymentCardState {
    data: unknown | null;
    loading: boolean;
    error: DeletePaymentCardError | null;
}

export interface SetDeletePaymentCardInitialAction {
    type: typeof DELETE_PAYMENT_CARD_INITIAL;
}

export interface DeletePaymentCardAction {
    type: typeof DELETE_PAYMENT_CARD;
}

export interface DeletePaymentCardSuccessAction {
    type: typeof DELETE_PAYMENT_CARD_SUCCESS;
    payload: unknown;
}

export interface DeletePaymentCardErrorAction {
    type: typeof DELETE_PAYMENT_CARD_ERROR;
    payload: DeletePaymentCardError;
}

export type DeletePaymentCardActions =
    | DeletePaymentCardAction
    | DeletePaymentCardSuccessAction
    | DeletePaymentCardErrorAction
    | SetDeletePaymentCardInitialAction;
