import { useEffect, useState } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { FaRegCheckCircle } from 'react-icons/fa';
import MyAccountPage from '../../pages/MyAccountPage';
import PageHeader from '../PageHeader';
import PropTypes from 'prop-types';
import { useIsLoggedIn } from '../../_hooks/useIsLoggedIn';

interface LoaderProps {
    message?: string;
}

export const Spinner: React.FC = () => {
    return (
        <div className="d-flex justify-content-center align-items-stretch m-5">
            <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
};

export const ErrorBlock: React.FC<LoaderProps> = ({ message }) => {
    return (
        <div className="container">
            <div className="row mt-lg-14">
                <div className="col-12">
                    <PageHeader iconComp={<FaRegCheckCircle />} title="Oops! Error occurred!" />

                    <div className={`alert alert-warning text-center`}>
                        <AiOutlineWarning className="alert-icon" />
                        <div className="alert-content pt-3">{message}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Loader: React.FC<LoaderProps> = ({ message = 'Something went wrong!' }) => {
    const [show, setShow] = useState(false);
    const isUserLoggedIn = useIsLoggedIn();

    useEffect(() => {
        const timer30seconds = setTimeout(() => setShow(true), 15 * 1000);
        return () => {
            clearTimeout(timer30seconds);
        };
    }, []);

    return (
        <>
            {!show ? (
                <Spinner />
            ) : (
                <>
                    {isUserLoggedIn ? (
                        <MyAccountPage>
                            <ErrorBlock message={message} />
                        </MyAccountPage>
                    ) : (
                        <ErrorBlock message={message} />
                    )}
                </>
            )}
        </>
    );
};

ErrorBlock.propTypes = {
    message: PropTypes.string,
};

Loader.propTypes = {
    message: PropTypes.string,
};

export default Loader;
