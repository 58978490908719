import {
    GET_PAYMENT_DETAILS,
    GET_PAYMENT_DETAILS_ERROR,
    GET_PAYMENT_DETAILS_INITIAL,
    GET_PAYMENT_DETAILS_SUCCESS,
    PaymentDetails,
    PaymentDetailsAction,
    PaymentDetailsError,
} from '../_types/paymentDetails';

export const setPaymentDetailsLoading = (): PaymentDetailsAction => {
    return {
        type: GET_PAYMENT_DETAILS,
    };
};

export const setPaymentDetailsInitial = (): PaymentDetailsAction => {
    return {
        type: GET_PAYMENT_DETAILS_INITIAL,
    };
};

export const setPaymentDetailsError = (err: PaymentDetailsError): PaymentDetailsAction => {
    return {
        type: GET_PAYMENT_DETAILS_ERROR,
        payload: err,
    };
};

export const setPaymentDetailsSuccess = (data: PaymentDetails): PaymentDetailsAction => {
    return {
        type: GET_PAYMENT_DETAILS_SUCCESS,
        payload: data,
    };
};
