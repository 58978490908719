import { messageChannel } from './login';
import { SalesChannel } from './salesChannel';

export const GET_USER_ACCOUNT = 'GET_USER_ACCOUNT';
export const GET_USER_ACCOUNT_SUCCESS = 'GET_USER_ACCOUNT_SUCCESS';
export const GET_USER_ACCOUNT_ERROR = 'GET_USER_ACCOUNT_ERROR';
export const USER_ACCOUNT_UPDATE = 'USER_ACCOUNT_UPDATE';
export const USER_ACCOUNT_UPDATE_SUCCESS = 'USER_ACCOUNT_UPDATE_SUCCESS';
export const USER_ACCOUNT_UPDATE_ERROR = 'USER_ACCOUNT_UPDATE_ERROR';
export const USER_ACCOUNT_INITIAL = 'USER_ACCOUNT_INITIAL';
export interface UserAccountRequest {
    signupToken: string;
}

export interface UserAccount {
    firstName?: string;
    lastName?: string;
    email?: string;
    language?: string;
    phone?: string;
    countryCode?: number;
    paymentToken?: string;
    seatPreference?: unknown;
    signupToken: string;
    salesChannelId: number;
    hasPassword?: string;
    completedAt?: string;
    createdAt?: string;
    subscribed?: boolean;
    additionalData?: string;
    rewardCode?: string;
    shortCodeSignup?: boolean;
    source?: string;
    campaign?: string;
    isPaid?: boolean;
    isClaimed?: boolean;
    emailToken?: string;
    salesChannel?: SalesChannel['SalesChannel'];
    resetTokens?: Array<unknown>;
    messageChannels?: Array<messageChannel>;
    id: string;
    charge?: number;
    password?: string;
    passwordConfirm?: string;
    passwordCurrent?: string;
    thirdPartyMemberId?: string;
}

export interface UserAccountError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface UserAccountState {
    data: UserAccount | null;
    loading: boolean;
    error: UserAccountError | null;
    update: boolean;
    finalizing: boolean;
}

export interface SetUserAccountInitialAction {
    type: typeof USER_ACCOUNT_INITIAL;
}

export interface GetUserAccountAction {
    type: typeof GET_USER_ACCOUNT;
}

export interface GetUserAccountSuccessAction {
    type: typeof GET_USER_ACCOUNT_SUCCESS;
    payload: UserAccount;
}

export interface GetUserAccountErrorAction {
    type: typeof GET_USER_ACCOUNT_ERROR;
    payload: UserAccountError;
}

export interface GetUserAccountUpdateAction {
    type: typeof USER_ACCOUNT_UPDATE;
}

export interface GetUserAccountUpdateSuccessAction {
    type: typeof USER_ACCOUNT_UPDATE_SUCCESS;
    payload: UserAccount;
}

export interface GetUserAccountUpdateErrorAction {
    type: typeof USER_ACCOUNT_UPDATE_ERROR;
    payload: UserAccountError;
}

export type UserAccountAction =
    | SetUserAccountInitialAction
    | GetUserAccountAction
    | GetUserAccountSuccessAction
    | GetUserAccountErrorAction
    | GetUserAccountUpdateAction
    | GetUserAccountUpdateSuccessAction
    | GetUserAccountUpdateErrorAction;
