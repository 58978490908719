import {
    FinalizeAccountAction,
    FINALIZE_ACCOUNT,
    FINALIZE_ACCOUNT_ERROR,
    FINALIZE_ACCOUNT_SUCCESS,
} from '../_types/finalizeAccount';
import {
    GET_USER_ACCOUNT,
    GET_USER_ACCOUNT_ERROR,
    GET_USER_ACCOUNT_SUCCESS,
    USER_ACCOUNT_UPDATE,
    USER_ACCOUNT_UPDATE_ERROR,
    USER_ACCOUNT_UPDATE_SUCCESS,
    USER_ACCOUNT_INITIAL,
    UserAccountAction,
    UserAccountState,
} from '../_types/userAccount';

const initialState: UserAccountState = {
    data: null,
    loading: false,
    error: null,
    update: false,
    finalizing: false,
};

export default (state = initialState, action: UserAccountAction | FinalizeAccountAction): UserAccountState => {
    switch (action.type) {
        case FINALIZE_ACCOUNT:
            return {
                ...state,
                loading: true,
                finalizing: false,
                data: null,
                error: null,
                update: false,
            };
        case FINALIZE_ACCOUNT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
                finalizing: true,
                update: false,
            };
        case FINALIZE_ACCOUNT_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
                finalizing: false,
                data: null,
                update: false,
            };
        case GET_USER_ACCOUNT:
            return {
                ...state,
                loading: true,
                finalizing: false,
                data: null,
                error: null,
                update: false,
            };
        case GET_USER_ACCOUNT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
                update: false,
                finalizing: false,
            };
        case GET_USER_ACCOUNT_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
                finalizing: false,
                data: null,
                update: false,
            };
        case USER_ACCOUNT_UPDATE_SUCCESS:
            return {
                ...state,
                update: true,
                data: action.payload,
                loading: false,
                error: null,
                finalizing: false,
            };
        case USER_ACCOUNT_UPDATE:
            return {
                ...state,
                loading: true,
                update: false,
                finalizing: false,
                error: null,
            };
        case USER_ACCOUNT_UPDATE_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
                finalizing: false,
            };
        case USER_ACCOUNT_INITIAL:
            return initialState;
        default:
            return state;
    }
};
