import React, { useEffect, useState } from 'react';
import './index.scss';
import PageHeader from '../PageHeader/index';

import { FaqSingle } from '../../_types/faq';
import { useSelector } from 'react-redux';
import { isEmptyArray } from 'formik';
import Text from '../Text';
import { salesChannelSelector } from '../../_selectors';
import { BiListMinus, BiListPlus } from 'react-icons/bi';
import { getChannelByUrl } from '../../config';

export const urlify = (text: string): (string | JSX.Element)[] => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part) => {
        if (part.match(urlRegex)) {
            return (
                <a href={part} target="_blank" rel="noreferrer" key={part}>
                    {part}
                </a>
            );
        }
        return part;
    });
};

const Faq: React.FC = () => {
    const { data } = useSelector(salesChannelSelector);
    const salesChannelConfig = getChannelByUrl(window.location.host);
    const source = sessionStorage.getItem('source');

    const sourceSpecificFaqs =
        salesChannelConfig &&
        source &&
        salesChannelConfig.faqOverrides &&
        (source ? salesChannelConfig.faqOverrides[source] : null);

    const [faqs, setFaqs] = useState<Array<FaqSingle>>([]);
    let faqArrayData: Array<FaqSingle> = [];

    useEffect(() => {
        if (data) {
            if (sourceSpecificFaqs) {
                faqArrayData = JSON.parse(sourceSpecificFaqs);
            } else if (data.faqPass) {
                faqArrayData = JSON.parse(data.faqPass);
            } else {
                faqArrayData = JSON.parse(data.faqDefault);
            }

            faqArrayData?.forEach((faq, index) => {
                faq.open = true;
                faq.id = index;
            });
            setFaqs(faqArrayData);
        }
    }, [data]);

    const toggleFaq = (faq: FaqSingle) => {
        if (faqs) {
            const result = faqs.map((el) => (el.id === faq.id ? { ...el, open: !faq.open } : el));

            setFaqs(result);
        }
    };

    return (
        <div className="row justify-content-center mt-sm-6 px-3 px-sm-6 py-6 faq-wrapper">
            <div className="border-bottom col-12">
                <PageHeader title="FAQ" />
                {isEmptyArray(faqs) && <Text message="FAQs couldn't be retrieved!" type="error" />}
            </div>
            {!isEmptyArray(faqs) &&
                faqs.map((faq) => (
                    <div className="col-12 align-self-center py-4 border-bottom" key={faq.id}>
                        <div className="d-flex align-items-start faq__question-wrapper" onClick={() => toggleFaq(faq)}>
                            <span className="text-primary faq__question-icon">
                                {faq.open ? <BiListPlus /> : <BiListMinus />}
                            </span>
                            <span className="font-weight-bold text-small ml-4" aria-expanded={faq.open}>
                                {faq.question}
                            </span>
                        </div>
                        <div className={`mt-4 ml-6 text-small ${faq.open ? 'd-none' : 'd-block'}`}>
                            {urlify(faq.answer)}
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default Faq;
