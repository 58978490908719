import {
    GET_USER_ACCOUNT_REFERRAL,
    GET_USER_ACCOUNT_REFERRAL_ERROR,
    GET_USER_ACCOUNT_REFERRAL_SUCCESS,
    SET_USER_ACCOUNT_REFERRAL,
    SET_USER_ACCOUNT_REFERRAL_ERROR,
    SET_USER_ACCOUNT_REFERRAL_SUCCESS,
    UserAccountReferralAction,
    UserAccountReferralState,
} from '../_types/userAccountReferral';

const initialState: UserAccountReferralState = {
    data: null,
    loading: false,
    error: null,
};

export default (state = initialState, action: UserAccountReferralAction): UserAccountReferralState => {
    switch (action.type) {
        case GET_USER_ACCOUNT_REFERRAL_SUCCESS:
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case GET_USER_ACCOUNT_REFERRAL:
            return {
                ...state,
                loading: true,
            };
        case GET_USER_ACCOUNT_REFERRAL_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case SET_USER_ACCOUNT_REFERRAL_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case SET_USER_ACCOUNT_REFERRAL:
            return {
                ...state,
                loading: true,
            };
        case SET_USER_ACCOUNT_REFERRAL_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};
