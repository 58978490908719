export const TOGGLE_LOGOUT_DIALOG = 'TOGGLE_LOGOUT_DIALOG';
export const TOGGLE_REGISTRATION_CONFIRM_DIALOG = 'TOGGLE_REGISTRATION_CONFIRM_DIALOG';

export interface DialogState {
    openLogOutDialog: boolean;
    openRegistraionConfirDialog: boolean;
}

export interface ToggleLogOutAction {
    type: typeof TOGGLE_LOGOUT_DIALOG;
    payload: boolean;
}

export interface ToggleRegConfirmAction {
    type: typeof TOGGLE_REGISTRATION_CONFIRM_DIALOG;
    payload: boolean;
}

export type DialogAction = ToggleRegConfirmAction | ToggleLogOutAction;
