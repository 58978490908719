import React from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../../_constants/routes';
import { setLogOutDialogConfirmation } from '../../_actions/dialog.actions';
import { setLoginInitial } from '../../_actions/login.actions';
import { dialogSelector } from '../../_selectors';
import { setUserAccountInitial } from '../../_actions/userAccount.actions';
import { setPaymentDetailsInitial } from '../../_actions/paymentDetails.actions';
import { setUpdatePaymentInitial } from '../../_actions/updatePayment.actions';

const LogOutDialogConfirm: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { openLogOutDialog } = useSelector(dialogSelector);
    const closeModal = () => {
        dispatch(setLogOutDialogConfirmation(!openLogOutDialog));
    };
    const logOut = () => {
        dispatch(setLogOutDialogConfirmation(!openLogOutDialog));
        sessionStorage.clear();
        dispatch(setUserAccountInitial());
        dispatch(setLoginInitial());
        dispatch(setPaymentDetailsInitial());
        dispatch(setUpdatePaymentInitial());
        history.push(ROUTES.LOGIN);
    };
    return (
        <>
            <Modal
                isOpen={openLogOutDialog}
                ariaHideApp={false}
                className="pass--dialog"
                style={{ overlay: { zIndex: 2 } }}
                shouldFocusAfterRender={true}
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Logout Confirmation!</h5>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure want to logout from your account?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                onClick={closeModal}
                                className="btn btn-secondary text-uppercase"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                            <button type="button" onClick={logOut} className="btn btn-primary text-uppercase">
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default LogOutDialogConfirm;
