import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

interface NoErrorLoaderProps {
    message?: string;
    loading?: boolean;
}

const NoErrorLoader: React.FC<NoErrorLoaderProps> = ({ loading = false }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timer5seconds = setTimeout(() => setShow(true), 5 * 1000);
        return () => {
            clearTimeout(timer5seconds);
        };
    }, []);

    return (
        <>
            <div className="d-flex justify-content-center align-items-stretch m-5">
                <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-primary" role="status" />
                <div className="spinner-grow text-primary" role="status" />
            </div>
            {show && loading ? (
                <div className={'text-center font-medium'}>
                    It&#39;s taking longer than usual.
                    <br />
                    Please wait...
                </div>
            ) : (
                ''
            )}
        </>
    );
};

NoErrorLoader.propTypes = {
    message: PropTypes.string,
    loading: PropTypes.bool,
};

export default NoErrorLoader;
