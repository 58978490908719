import { UserAccount, UserAccountError } from './userAccount';

export const FINALIZE_ACCOUNT = 'FINALIZE_ACCOUNT';
export const FINALIZE_ACCOUNT_SUCCESS = 'FINALIZE_ACCOUNT_SUCCESS';
export const FINALIZE_ACCOUNT_ERROR = 'FINALIZE_ACCOUNT_ERROR';

export interface FinalizeAccountRequest {
    email: string;
    paymentToken: string;
    rewardCode?: {
        code: string;
        amount: number;
    };
    salesChannel: number;
    charge?: number;
    seatPreferance?: string;
    signupToken: string;
    street?: string;
    apartment?: string;
    city?: string;
    zipcode?: string;
}

export interface SetFinalizeAccountAction {
    type: typeof FINALIZE_ACCOUNT;
}

export interface FinalizeAccountSuccessAction {
    type: typeof FINALIZE_ACCOUNT_SUCCESS;
    payload: UserAccount;
}

export interface FinalizeAccountErrorAction {
    type: typeof FINALIZE_ACCOUNT_ERROR;
    payload: UserAccountError;
}

export type FinalizeAccountAction =
    | SetFinalizeAccountAction
    | FinalizeAccountSuccessAction
    | FinalizeAccountErrorAction;
