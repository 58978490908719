export const UPDATE_CONTACT_INFO = 'UPDATE_CONTACT_INFO';
export const UPDATE_CONTACT_INFO_INITIAL = 'UPDATE_CONTACT_INFO_INITIAL';
export const UPDATE_CONTACT_INFO_SUCCESS = 'UPDATE_CONTACT_INFO_SUCCESS';
export const UPDATE_CONTACT_INFO_ERROR = 'UPDATE_CONTACT_INFO_ERROR';

export interface UpdateContactInfoRequest {
    phone?: string;
    email?: string;
    id: string;
    salesChannelId: number;
}

export interface UpdateContactInfo {
    status: string;
}

export interface UpdateContactInfoError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface UpdateContactInfoState {
    data: Array<UpdateContactInfo> | null;
    loading: boolean;
    error: UpdateContactInfoError | null;
}

export interface UpdateContactInfoAction {
    type: typeof UPDATE_CONTACT_INFO;
}

export interface UpdateContactInfoInitialAction {
    type: typeof UPDATE_CONTACT_INFO_INITIAL;
}

export interface UpdateContactInfoSuccessAction {
    type: typeof UPDATE_CONTACT_INFO_SUCCESS;
    payload: Array<UpdateContactInfo>;
}

export interface UpdateContactInfoErrorAction {
    type: typeof UPDATE_CONTACT_INFO_ERROR;
    payload: UpdateContactInfoError;
}

export type UpdateContactInfoOptionsAction =
    | UpdateContactInfoAction
    | UpdateContactInfoSuccessAction
    | UpdateContactInfoInitialAction
    | UpdateContactInfoErrorAction;
