export const POST_NEXT_SIGNUP_FLOW_MESSAGE = 'POST_NEXT_SIGNUP_FLOW_MESSAGE';
export const POST_NEXT_SIGNUP_FLOW_MESSAGE_SUCCESS = 'POST_NEXT_SIGNUP_FLOW_MESSAGE_SUCCESS';
export const POST_NEXT_SIGNUP_FLOW_MESSAGE_ERROR = 'POST_NEXT_SIGNUP_FLOW_MESSAGE_ERROR';

export interface NextSignupFlowMessageRequest {
    messageChannelType?: string;
    userId: string;
}

export interface NextSignupFlowMessage {
    req: {
        method: string;
        url: string;
        data: NextSignupFlowMessageRequest;
    };
    header: {
        'content-type': string;
        date: string;
        etag: string;
        server: string;
        status: string;
        'x-powered-by': string;
        'content-length': string;
        connection: string;
    };
    status: number;
    text: string;
}

export interface NextSignupFlowMessageError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface NextSignupFlowMessageState {
    data: NextSignupFlowMessage | null;
    loading: boolean;
    error: NextSignupFlowMessageError | null;
}

export interface NextSignupFlowMessageAction {
    type: typeof POST_NEXT_SIGNUP_FLOW_MESSAGE;
}

export interface NextSignupFlowMessageSuccessAction {
    type: typeof POST_NEXT_SIGNUP_FLOW_MESSAGE_SUCCESS;
    payload: NextSignupFlowMessage;
}

export interface NextSignupFlowMessageErrorAction {
    type: typeof POST_NEXT_SIGNUP_FLOW_MESSAGE_ERROR;
    payload: NextSignupFlowMessageError;
}

export type SignupFlowMessageAction =
    | NextSignupFlowMessageAction
    | NextSignupFlowMessageSuccessAction
    | NextSignupFlowMessageErrorAction;
