import { ReactElement } from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import ReactModal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import { useIsOkta } from '../../_hooks/useIsOkta';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';

type VideoModalProps = {
    isOpen: boolean;
    onRequestClose: () => void;
    videoUrl: string | undefined;
    sliderImages?: null | string[];
};

export function VideoModal({ isOpen, onRequestClose, videoUrl, sliderImages }: VideoModalProps): ReactElement {
    const isOkta = useIsOkta();
    const modalClasses = isOkta ? `okta-login okta-login-modal` : `tm-login tm-login-modal`;
    return (
        <ReactModal
            isOpen={isOpen}
            contentLabel="Video"
            style={modalStyles}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={true}
            onRequestClose={onRequestClose}
            className={modalClasses}
        >
            <Carousel
                showThumbs={false}
                showStatus={false}
                swipeable={true}
                useKeyboardArrows={true}
                className="pass--carousel"
                renderArrowNext={(onClickHandler, hasNext) =>
                    hasNext && (
                        <BiChevronRight onClick={onClickHandler} color="white" className="carousel--next-arrow" />
                    )
                }
                renderArrowPrev={(onClickHandler, hasPrev) =>
                    hasPrev && <BiChevronLeft onClick={onClickHandler} color="white" className="carousel--prev-arrow" />
                }
                showArrows={!!sliderImages?.length}
                showIndicators={!!sliderImages?.length}
            >
                {(sliderImages ? [videoUrl, ...sliderImages] : [videoUrl])?.map((videoOrImage, index) => {
                    return (
                        <div
                            className={`mx-auto md:mt-14 relative text-center ${isOkta ? 'okta' : 'tm'}-modal`}
                            key={index}
                        >
                            <FaTimesCircle
                                color={'white'}
                                size={24}
                                onClick={onRequestClose}
                                className={'absolute right-2 top-2 cursor-pointer rounded-full bg-gray-600'}
                                style={{ zIndex: 10 }}
                            />
                            {index === 0 && (
                                <video style={{ width: '100%' }} autoPlay muted playsInline controls={true}>
                                    <source src={videoOrImage} type="video/mp4" />
                                </video>
                            )}
                            {index > 0 && !!videoOrImage && (
                                <img src={videoOrImage} alt="slider-image" className="img-fluid" />
                            )}
                        </div>
                    );
                })}
            </Carousel>
        </ReactModal>
    );
}

const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,.7)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        outline: 'none',
    },
};
