export const SAVE_PASSWORD = 'SAVE_PASSWORD';
export const SAVE_PASSWORD_INITIAL = 'SAVE_PASSWORD_INITIAL';
export const SAVE_PASSWORD_SUCCESS = 'SAVE_PASSWORD_SUCCESS';
export const SAVE_PASSWORD_ERROR = 'SAVE_PASSWORD_ERROR';

export interface SavePasswordRequest {
    salesChannelId: number;
    signupToken: string;
    password: string;
}

export interface SavePassword {
    email: string;
    phone: string;
    createdAt: string;
    id: string;
}

export interface SavePasswordError {
    body?: {
        message?: string;
        status?: string;
    };
    message?: string;
    name?: string;
    status?: number;
}

export interface SavePasswordState {
    data: SavePassword | null;
    loading: boolean;
    error: SavePasswordError | null;
}

export interface SavePasswordAction {
    type: typeof SAVE_PASSWORD;
}

export interface SavePasswordInitialAction {
    type: typeof SAVE_PASSWORD_INITIAL;
}

export interface SavePasswordSuccessAction {
    type: typeof SAVE_PASSWORD_SUCCESS;
    payload: SavePassword;
}

export interface SavePasswordErrorAction {
    type: typeof SAVE_PASSWORD_ERROR;
    payload: SavePasswordError;
}

export type SavePasswordOptionsAction =
    | SavePasswordAction
    | SavePasswordSuccessAction
    | SavePasswordInitialAction
    | SavePasswordErrorAction;
