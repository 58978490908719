import { ReactElement } from 'react';

export function LegalDisclaimer({
    salesChannelId,
    salesChannelName,
    tosURL,
    privacyPolicyURL,
}: Record<string, string>): ReactElement {
    return (
        <div className={'p-10 py-3 px-6 rounded-b-lg text-xs text-justify'}>
            By clicking Sign In, you agree to the Ticketmaster{' '}
            <a
                href="https://help.ticketmaster.com/s/article/Terms-of-Use?language=en_US"
                target={'_blank'}
                className={'underline'}
                rel="noreferrer"
            >
                Terms of Use
            </a>{' '}
            and{' '}
            <a
                href="https://help.ticketmaster.com/s/article/Ticketmaster-Privacy-Policy?language=en_US"
                className={'underline'}
                target={'_blank'}
                rel="noreferrer"
            >
                Privacy Policy
            </a>
            , {salesChannelName}{' '}
            <a href={tosURL} className={'underline'} target={'_blank'} rel="noreferrer">
                Terms of Use
            </a>
            {salesChannelId !== '141' ? ' and ' : ', '}
            <a href={privacyPolicyURL} className={'underline'} target={'_blank'} rel="noreferrer">
                Privacy Policy
            </a>
            {salesChannelId === '141' && ( // canucks specific
                <span>
                    {' '}
                    and{' '}
                    <a
                        href="https://www.nhl.com/canucks/info/ticket-terms/#SCHEDULE-A-ROGERS"
                        className={'underline'}
                        target={'_blank'}
                        rel="noreferrer"
                    >
                        Ticket Terms
                    </a>
                </span>
            )}
            , and the Pogoseat{' '}
            <a
                href="https://www.pogoseat.com/legal/terms-and-conditions"
                className={'underline'}
                target={'_blank'}
                rel="noreferrer"
            >
                Terms and Conditions
            </a>{' '}
            and{' '}
            <a
                href="https://www.pogoseat.com/legal/privacy-policy"
                className={'underline'}
                target={'_blank'}
                rel="noreferrer"
            >
                Privacy Policy
            </a>
            . As described further in the Terms, you agree that {salesChannelName}, and its service providers, Pogoseat
            and Ticketmaster, may send you automated text messages with offers promotion relating to tickets at{' '}
            {salesChannelName} events. Message and data rates may apply. Messages are recurring and frequency varies.
            Consent is not a condition of purchase.
        </div>
    );
}
