import { useSelector } from 'react-redux';
import { ChannelConfig, getChannelById } from '../config';
import { salesChannelSelector } from '../_selectors';
import { SalesChannel } from '../_types/salesChannel';

type salesChannelType = {
    textBuyUI: SalesChannel['TextBuyUi'];
    salesChannelUi: SalesChannel['SalesChannel'];
    salesChannelId: number;
    textBuySSO: SalesChannel['TextBuySSO'];
    salesChannelName: string;
    salesChannelProvider: string;
    sourceSpecificLogo: string | null | undefined;
    sourceSpecificSignupSubTitle: string | null | undefined;
    sourceSpecificInviteSubTitle: string | null | undefined;
    sourceSpecificBackground: string | null | undefined;
    sourceSpecificLogoBackground: string | null | undefined;
    sourceSpecificThumbnail: string | null | undefined;
    sourceSpecificSliderImages: string[] | undefined;
    salesChannelConfig: ChannelConfig;
};

export const useSalesChannel = (): salesChannelType => {
    const salesChannelData = useSelector(salesChannelSelector).data;
    const textBuyUI = salesChannelData?.TextBuyUi;
    const salesChannelUi = salesChannelData?.SalesChannel;
    const salesChannelId = textBuyUI?.salesChannelId || 0;
    const textBuySSO = salesChannelData?.TextBuySSO;

    const source = sessionStorage.getItem('source');
    const salesChannelConfig = getChannelById(salesChannelId || 0);

    const salesChannelName = salesChannelConfig && salesChannelConfig.fullName ? salesChannelConfig.fullName : '';
    const salesChannelProvider = salesChannelConfig && salesChannelConfig.provider ? salesChannelConfig.provider : '';

    const sourceSpecificLogo =
        salesChannelConfig &&
        source &&
        salesChannelConfig.logoOverrides &&
        (source ? salesChannelConfig.logoOverrides[source] : null);

    const sourceSpecificSignupSubTitle =
        salesChannelConfig &&
        source &&
        salesChannelConfig.signupSubTitleOverrides &&
        (source ? salesChannelConfig.signupSubTitleOverrides[source] : null);

    const sourceSpecificInviteSubTitle =
        salesChannelConfig &&
        source &&
        salesChannelConfig.inviteSubHeadingOverrides &&
        (source ? salesChannelConfig.inviteSubHeadingOverrides[source] : null);

    const sourceSpecificBackground =
        salesChannelConfig &&
        source &&
        salesChannelConfig.backgroundOverrides &&
        (source ? salesChannelConfig.backgroundOverrides[source] : null);

    const sourceSpecificLogoBackground =
        salesChannelConfig &&
        source &&
        salesChannelConfig.logoBackgroundOverrides &&
        (source ? salesChannelConfig.logoBackgroundOverrides[source] : null);

    const sourceSpecificThumbnail =
        salesChannelConfig &&
        source &&
        salesChannelConfig.thumbnailOverrides &&
        (source ? salesChannelConfig.thumbnailOverrides[source] : null);

    const sourceSpecificSliderImages =
        salesChannelConfig && salesChannelConfig.sliderOnLandingPage && source
            ? salesChannelConfig.sliderOnLandingPage[source]
            : undefined;

    return {
        textBuyUI,
        salesChannelUi,
        salesChannelId,
        textBuySSO,
        salesChannelName,
        salesChannelProvider,
        sourceSpecificLogo,
        sourceSpecificBackground,
        sourceSpecificLogoBackground,
        sourceSpecificSignupSubTitle,
        sourceSpecificInviteSubTitle,
        sourceSpecificThumbnail,
        sourceSpecificSliderImages,
        salesChannelConfig,
    };
};
